import Vue from "vue";
import Vuex from "vuex";
import userAPI from "@/apis/user";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        userInfo: null,
        currentConnectedWalletAddress: "",
        launchMethod: "BOT", // BOT or Miniapp
        isSettingsShow: false,
        videoQuality: null,
    },
    getters: {
        userInfo: (state) => state.userInfo,
        currentConnectedWalletAddress: (state) =>
            state.currentConnectedWalletAddress,
        launchMethod: (state) => state.launchMethod,
        currentCoinBalance: (state) => {
            return state.userInfo.balance && state.userInfo.balance["Coin"]
                ? Number(state.userInfo.balance["Coin"].balance)
                : 0;
        },
        currentBonusBalance: (state) => {
            return state.userInfo.balance && state.userInfo.balance["Bonus"]
                ? Number(state.userInfo.balance["Bonus"].balance)
                : 0;
        },
        isSettingsShow: (state) => state.isSettingsShow,
        videoQuality: (state) => state.videoQuality,
    },
    mutations: {
        // 登入
        setUserInfo(state, userInfo) {
            console.log("set user info");
            state.userInfo = {
                ...state.userIfo,
                ...userInfo,
            };
        },
        // 連接錢包成功
        setCurrentConnectedWalletAddress(state, walletAddress) {
            console.log("set currentConnectedWalletAddress");
            state.currentConnectedWalletAddress = walletAddress;
        },
        // openlink: MINIAPP;  bot:BOT
        setLaunchMethod(state, _launchMethod) {
            console.log("set launchMethod");
            state.launchMethod = _launchMethod;
        },
        setIsSettingsShow(state, isShow) {
            console.log("set isSettingsShow");
            state.isSettingsShow = isShow;
        },
        setVideoQuality(state, quality) {
            state.videoQuality = quality;
        },
    },
    actions: {
        async updateInitDataUserInfo({ commit, state }) {
            try {
                const initDataUnsafe = Telegram.WebApp.initDataUnsafe; //await userAPI.getUserInfo();
                // if (res.status !== 200) {
                //     const errMessage = res.data;
                //     throw new Error(errMessage);
                // }
                const userInfo = {
                    balance: {},
                    user: {
                        _id: `${initDataUnsafe && initDataUnsafe.user && initDataUnsafe.user.id}`,
                        username:
                            (initDataUnsafe &&
                                initDataUnsafe.user &&
                                initDataUnsafe.user.username) ||
                            "",
                        tg_info: initDataUnsafe && initDataUnsafe.user,
                    },
                };
                console.log(
                    "updateInitDataUserInfo initDataUnsafe: ",
                    initDataUnsafe,
                );
                commit("setUserInfo", userInfo);
                return null;
            } catch (err) {
                console.log("updateInitDataUserInfo err: ", err);
                return err;
            }
        },
        async updateUserInfo({ commit, state }) {
            try {
                const res = await userAPI.getUserInfo();
                if (res.status !== 200) {
                    const errMessage = res.data;
                    throw new Error(errMessage);
                }
                const userInfo = res.data;
                console.log("updateUserInfo res: ", res);
                commit("setUserInfo", userInfo);
                return null;
            } catch (err) {
                console.log("updateUserInfo err: ", err);
                return err;
            }
        },
    },
    modules: {},
});
