import { apiHelper } from "@/utils/requestHelper.js";

export default {
    // 獲取影集列表
    getFilms() {
        return apiHelper.get("/video/shows");
    },
    // 獲取喜愛影集列表
    getFavoriteFilms() {
        return apiHelper.get("/video/getFavorites");
    },
    // 獲取特定影集資訊
    getFilm(filmId) {
        return apiHelper.get(`/video/show?showId=${filmId}`);
    },
    // 獲取特定影集解鎖狀態
    getFilmUnlockStatus(filmId) {
        return apiHelper.get(`/video/episodes?showId=${filmId}`);
    },
    // 獲取影片資源連結
    getEpisodeSourceURL(filmId, episode) {
        return apiHelper.get(
            `/video/playUrl?showId=${filmId}&episode=${episode}`,
        );
    },
    // 收藏影集
    favoriteFilm(payload) {
        return apiHelper.post("/video/addFavorite", payload);
    },
    // 取消收藏影集
    unfavoriteFilm(payload) {
        return apiHelper.post("/video/removeFavorite", payload);
    },
    // 存取影片進度
    saveEpisodeProgress(payload) {
        return apiHelper.post("/video/records", payload);
    },
    // 獲取最新觀看紀錄
    getEpisodeRecords() {
        return apiHelper.get("/video/records");
    },
    // 付費解鎖劇集
    unlockEpisodes(payload) {
        return apiHelper.post("/video/unlock", payload);
    },
};
