<template>
    <div class="no-scrollbar absolute left-0 top-0 h-screen w-screen">
        <!-- 影片主體 -->
        <div v-if="isReadyToDisplay" class="relative h-full w-full">
            <!-- preload image cover -->

            <VideoPlayer
                @onVideoLoaded="onEpisodeLoaded"
                @onPlay="onEpisodePlay"
                @onPause="isPaused = true"
                @onCanPlay="onEpisodeCanPlay"
                @onWaiting="onEpisodeWaiting"
                @updateProgress="updateProgress"
                @onEnded="onEpisodeEnded"
                :refName="'mainVideo'"
                :coverScreen="false"
                :isMute="false"
                :isPaused="isPaused"
                :currentEpisodeStartTime="currentEpisodeStartTime"
                :currentPlayingSeconds="currentPlayingSeconds"
                :videoSrc="currentEpisodeSourceURL"
            />
            <img
                v-if="!isVideoLoaded"
                class="absolute left-0 top-0 z-0 h-full w-full object-cover"
                :class="{ '!hidden': isVideoLoaded }"
                :src="currentFilmPicURL"
            />
        </div>
        <!-- 播放介面 -->
        <div
            v-if="isReadyToDisplay"
            @click="panelOnClick"
            class="absolute left-0 top-0 flex h-full w-full flex-col"
        >
            <!-- 播放介面內容 -->
            <div
                v-show="showControlPanel && !isSettingsShow"
                class="relative flex w-full flex-grow flex-col justify-end pb-10"
            >
                <!-- settings 按鈕 -->
                <button
                    @click.stop="settingsBtnOnClick"
                    class="absolute right-4 top-4 flex h-7 w-7 items-center justify-center text-center"
                >
                    <img
                        src="assets/imgs/views/index/settings_button_icon.png"
                        alt=""
                        class="h-7 w-7"
                    />
                </button>
                <!-- 播放與暫停鍵 -->
                <button
                    v-show="!isWaiting"
                    @click.stop="toggleIsPaused"
                    class="absolute left-1/2 top-1/2 h-[100px] w-[100px] -translate-x-1/2 -translate-y-1/2 overflow-hidden rounded-full text-[#fff]"
                >
                    <img
                        v-show="!isPaused"
                        src="assets/imgs/views/index/pause_button_icon.svg"
                        alt=""
                        class="h-full w-full"
                    />
                    <img
                        v-show="isPaused"
                        src="assets/imgs/views/index/play_button_icon.svg"
                        alt=""
                        class="h-full w-full"
                    />
                </button>
                <!-- 操作內容 -->
                <div class="flex w-full items-end justify-between px-4">
                    <!-- 劇集名稱與集數 -->
                    <div class="flex flex-col gap-2">
                        <span
                            class="max-w-[200px] whitespace-pre-line text-[40px] font-bold italic leading-10 text-[#fff]"
                            >{{ currentFilmName }}</span
                        >
                        <span class="text-xs font-bold text-[#fff]">{{
                            $t("views.index.episodeNumberText", {
                                episodeNumber: $route.query.episode,
                            })
                        }}</span>
                    </div>
                    <!-- action group -->
                    <div class="flex h-full flex-col items-center gap-4">
                        <!-- like 按鈕 -->
                        <button
                            @click.stop="likeBtnOnClick"
                            class="flex flex-col items-center gap-2"
                        >
                            <img
                                src="assets/imgs/views/index/like_button_icon.png"
                                alt=""
                                class="h-[30px] w-[30px] object-contain"
                            />
                            <span
                                class="text-center text-xs text-[#fff] [text-shadow:_1px_1px_1px_rgb(0_0_0_/_25%)]"
                                >{{ $t("views.index.bonusBtnText") }}</span
                            >
                        </button>
                        <!-- list 按鈕 -->
                        <button
                            @click.stop="listBtnOnClick"
                            :disabled="
                                !favoriteFilms || isFavoriteActionChecking
                            "
                            class="flex flex-col items-center gap-2"
                        >
                            <img
                                v-if="isCurrentFilmFavorite"
                                src="assets/imgs/views/index/list_button_active_icon.png"
                                alt=""
                                class="h-[30px] w-[30px] object-contain"
                            />
                            <img
                                v-else
                                src="assets/imgs/views/index/list_button_icon.png"
                                alt=""
                                class="h-[30px] w-[30px] object-contain"
                            />
                            <span
                                class="text-center text-xs text-[#fff] [text-shadow:_1px_1px_1px_rgb(0_0_0_/_25%)]"
                                >{{ $t("views.index.listBtnText") }}</span
                            >
                        </button>
                        <!-- share 按鈕 -->
                        <button
                            @click.stop="shareBtnOnClick"
                            class="flex flex-col items-center gap-2"
                        >
                            <img
                                src="assets/imgs/views/index/share_button_icon.png"
                                alt=""
                                class="h-[30px] w-[30px] object-contain"
                            />
                            <span
                                class="text-center text-xs text-[#fff] [text-shadow:_1px_1px_1px_rgb(0_0_0_/_25%)]"
                                >{{ $t("views.index.shareBtnText") }}</span
                            >
                        </button>
                    </div>
                </div>
                <!-- 進度條 -->
                <div
                    @click.stop
                    class="index-video-slider-wrapper absolute bottom-0 w-full"
                >
                    <a-slider
                        :min="0"
                        :max="1000"
                        :value="
                            (Number(playProgressForDisplay) /
                                Number(currentTotalSeconds)) *
                            1000
                        "
                        @change="playProgressOnChange"
                        :default-value="0"
                        :tooltipVisible="false"
                    />
                </div>
            </div>
            <!-- bottom panel -->
            <div
                @click.stop
                v-show="showControlPanel && !isSettingsShow"
                class="flex w-full items-center gap-3 px-4 py-6"
            >
                <!-- films 按鈕 -->
                <button
                    @click="filmsBtnOnclick"
                    class="flex h-12 w-12 items-center justify-center rounded-xl bg-[#000] bg-opacity-30"
                    style="backdrop-filter: blur(10px)"
                >
                    <img
                        src="assets/imgs/views/index/films_icon.svg"
                        alt=""
                        class="h-6 w-6"
                    />
                </button>
                <!-- episodes 按鈕 -->
                <button
                    @click="episodeBtnOnClick"
                    class="flex h-12 flex-grow items-center justify-between rounded-xl bg-[#000] bg-opacity-30 px-3"
                    style="backdrop-filter: blur(10px)"
                >
                    <div class="flex items-center gap-3">
                        <img
                            src="assets/imgs/views/index/episode_icon.svg"
                            alt=""
                            class="h-6 w-6"
                        />
                        <span class="text-base text-[#fff]">{{
                            $t("views.index.episodeBtnText", {
                                episodeAmount: currentFilmTotalEpisodeAmount,
                            })
                        }}</span>
                    </div>
                    <img
                        src="assets/imgs/views/index/arrow_up_icon.svg"
                        alt=""
                        class="h-6 w-6"
                    />
                </button>
                <!-- user 按鈕 -->
                <button
                    @click="userBtnOnClick"
                    class="flex h-12 w-12 items-center justify-center rounded-xl bg-[#000] bg-opacity-30"
                    style="backdrop-filter: blur(10px)"
                >
                    <img
                        src="assets/imgs/views/index/user_icon.svg"
                        alt=""
                        class="h-6 w-6"
                    />
                </button>
            </div>
        </div>
        <FilmSelectPanel
            :isFilmSelectPanelActive="isFilmSelectPanelActive"
            @close="isFilmSelectPanelActive = false"
        />
        <EpisodeSelectPanel
            :isEpisodeSelectPanelActive="isEpisodeSelectPanelActive"
            :filmInfo="currentFilmInfo"
            :shouldSelectEpisodePanelUpdateUnlockStatus.sync="
                shouldSelectEpisodePanelUpdateUnlockStatus
            "
            @close="isEpisodeSelectPanelActive = false"
            @episodeSelected="episodeSelected"
            @unlockEpisode="unlockEpisode"
        />
        <EpisodeUnlockPanel
            :isEpisodeUnlockPanelActive.sync="isEpisodeUnlockPanelActive"
            :filmInfo="currentFilmInfo"
            :shouldAutoUnlock.sync="shouldAutoUnlock"
            @close="isEpisodeUnlockPanelActive = false"
            @episodeUnlocked="onEpisodeUnlocked"
        />
        <SharePanel
            :isSharePanelActive="isSharePanelActive"
            @close="isSharePanelActive = false"
        />
        <Loading v-if="!isReadyToDisplay" :fullScreen="true" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Decimal from "decimal.js";
import videoAPI from "@/apis/video";
import VideoPlayer from "@/components/VideoPlayer.vue";
import FilmSelectPanel from "./components/panels/FilmSelectPanel.vue";
import EpisodeSelectPanel from "./components/panels/EpisodeSelectPanel.vue";
import EpisodeUnlockPanel from "./components/panels/EpisodeUnlockPanel.vue";
import SharePanel from "./components/panels/SharePanel.vue";
import Loading from "@/components/Loading.vue";

export default {
    name: "Index",
    components: {
        VideoPlayer,
        FilmSelectPanel,
        EpisodeSelectPanel,
        EpisodeUnlockPanel,
        SharePanel,
        Loading,
    },
    data: () => ({
        // ******** 當前播放劇集相關 ******** //
        // 喜愛動作是否驗證中
        isFavoriteActionChecking: false,
        // 喜愛片單
        favoriteFilms: null,
        // 當前播放中的影集資料
        currentFilmInfo: null,
        // 用戶當前解鎖進度
        userUnlockEpisodeStatus: null,
        // 當前播放影片接續秒數
        currentEpisodeStartTime: 0,
        // 當前播放用的影片資源
        currentEpisodeSource: null,
        // 是否暫停中
        isPaused: true,
        // 是否加載中
        isWaiting: false,
        // 是否資源加載完畢
        isVideoLoaded: false,
        // 當前影片播放進度 (秒數)
        currentPlayingSeconds: 0,
        // 當前影片總長度 (秒數)
        currentTotalSeconds: 1,
        //? 用來顯示進度條用的進度 (秒數) 注意: 不要寫入(只有 video emit 事件能操作寫入),  其餘地方 read only
        playProgressForDisplay: 0,
        // 是否顯示控制面板
        showControlPanel: true,
        // 當前正在解鎖的劇集參數 ({filmInfo, episodeNumber})
        currentUnlockingEpisodeConfig: null,
        // 用來觸發自動解鎖的 hook
        shouldAutoUnlock: false,
        // ******** 事件向子元件傳遞用的 hooks ******** //
        shouldSelectEpisodePanelUpdateUnlockStatus: false,
        // ******** UI panel 元件 active 狀態管理 ******** //
        // 是否顯示影集選擇面板
        isFilmSelectPanelActive: false,
        // 是否顯示 episode 選擇面板
        isEpisodeSelectPanelActive: false,
        // 是否顯示 episode 解鎖方案面板
        isEpisodeUnlockPanelActive: false,
        // 是否顯示分享面板
        isSharePanelActive: false,
        // ******** Timers ******** //
        // 隱藏控制面板用的到計時器
        panelHideTimer: null,
        // 紀錄當前影片進度用的計時器
        videoProgressRecorderTimer: null,
    }),
    computed: {
        ...mapGetters(["isSettingsShow"]),
        // 當前播放用的影片資源
        currentEpisodeSourceURL() {
            const currentLocale = this.$i18n.locale;
            const localeUrl =
                this.currentEpisodeSource &&
                this.currentEpisodeSource[currentLocale];
            const defaultUrl =
                this.currentEpisodeSource && this.currentEpisodeSource.url;
            let url = localeUrl || defaultUrl;
            if (window.location.hostname === "localhost") {
                url = `https://devvideomini.tongifts.app${url}`;
            }
            return this.currentEpisodeSource ? url : null;
        },
        // 當前影集名稱
        currentFilmName() {
            const currentLocale = this.$i18n.locale;
            const filmNameLocale =
                this.currentFilmInfo &&
                this.currentFilmInfo.name &&
                this.currentFilmInfo.name[currentLocale];
            const filmNameEn =
                this.currentFilmInfo &&
                this.currentFilmInfo.name &&
                this.currentFilmInfo.name["en"];
            return filmNameLocale || filmNameEn || "";
        },
        // 當前影集封面
        currentFilmPicURL() {
            let url = this.currentFilmInfo && this.currentFilmInfo.pic;
            if (window.location.hostname === "localhost") {
                url = `https://devvideomini.tongifts.app${url}`;
            }
            return url;
        },
        // 當前影集全部的劇集數量
        currentFilmTotalEpisodeAmount() {
            return (
                this.currentFilmInfo &&
                this.currentFilmInfo.episodes &&
                this.currentFilmInfo.episodes.totalNumber
            );
        },
        // 單前影集已解鎖的劇集數量
        currentFilmUnlockedEpisodeAmount() {
            const currentFilmDefaultUnlockNumber =
                this.currentFilmInfo &&
                this.currentFilmInfo.episodes &&
                this.currentFilmInfo.episodes.unlockNumber;
            const userUnlockNumber =
                this.userUnlockEpisodeStatus &&
                this.userUnlockEpisodeStatus.unlockNumber;
            return userUnlockNumber || currentFilmDefaultUnlockNumber;
        },
        // 是否準備好展示 (隱藏 loading 遮罩)
        isReadyToDisplay() {
            return this.currentEpisodeSourceURL && this.currentFilmInfo;
        },
        // 當前影集是否已被喜愛
        isCurrentFilmFavorite() {
            const currentFilmId = this.$route.query.film;
            return (
                this.favoriteFilms &&
                this.favoriteFilms.length > 0 &&
                this.favoriteFilms.includes(currentFilmId)
            );
        },
    },
    watch: {
        // 監聽 query string 更新
        "$route.query": {
            handler: function (newQuery, oldQuery) {
                const { film, episode, startTime, openPanel } = newQuery;
                console.log("film: ", film);
                console.log("episode: ", episode);
                console.log("startTime: ", startTime);
                console.log("openPanel: ", openPanel);
                // 如果有指定開啟 panel ，一秒後開啟對應 panel
                setTimeout(() => {
                    if (openPanel) {
                        if (openPanel === "selectFilm") {
                            this.isFilmSelectPanelActive = true;
                        }
                    }
                }, 1000);
                // 如果有指定劇集就獲取該劇集資源
                if (film && episode) {
                    this.isVideoLoaded = false;
                    this.getEpisodeSource(film, episode);
                    this.getFilmInfo(film);
                    if (startTime) {
                        this.currentEpisodeStartTime = Number(startTime);
                    }
                } else {
                    // 如果沒有指定劇集就獲取最近一次觀看紀錄
                    this.getLatestEpisodeRecord();
                }
                // 清空收藏列表並重新獲取收藏列表
                this.getFavoriteFilms();
            },
            immediate: true,
        },
        // 影片開始播放時開始紀錄進度 暫停時清除 timer
        isPaused(newVal) {
            if (!newVal) {
                // 每 10 秒紀錄一次
                this.videoProgressRecorderTimer = setInterval(() => {
                    this.recordCurrentEpisodeProgress();
                }, 10000);
            } else {
                this.clearVideoProgressRecorderTimer();
                // 暫停時紀錄一次
                this.recordCurrentEpisodeProgress();
            }
        },
    },
    methods: {
        // 獲取最新觀看紀錄
        async getLatestEpisodeRecord() {
            try {
                const res = await videoAPI.getEpisodeRecords();
                if (res.status !== 200) {
                    throw new Error(res);
                } else {
                    console.log("getLatestEpisodeRecord res: ", res);
                    const latestEpisodeRecord = res.data && res.data[0];
                    if (latestEpisodeRecord) {
                        const latestEpisodeRecordFilmId =
                            latestEpisodeRecord &&
                            latestEpisodeRecord.show &&
                            latestEpisodeRecord.show._id;
                        const latestEpisodeRecordEpisodeNumber =
                            latestEpisodeRecord && latestEpisodeRecord.episode;
                        this.currentEpisodeStartTime =
                            latestEpisodeRecord && latestEpisodeRecord.second;
                        this.$router.push({
                            query: {
                                film: latestEpisodeRecordFilmId,
                                episode: latestEpisodeRecordEpisodeNumber,
                            },
                        });
                    } else {
                        this.getRecommendedEpisode();
                    }
                }
            } catch (err) {
                console.log("getLatestEpisodeRecord err: ", err);
                this.$message.error(this.$t("messages.errors.defaultError"), 3);
            }
        },
        //TODO 沒有觀看紀錄時，獲取推薦劇集 (暫時先用第一個影集當作推薦影集)
        async getRecommendedEpisode() {
            try {
                const res = await videoAPI.getFilms();
                if (res.status !== 200) {
                    throw new Error(res);
                } else {
                    console.log("getRecommendedEpisode res: ", res);
                    const recommendedFilmInfo = res.data && res.data[0];
                    const recommendedFilmInfoId =
                        recommendedFilmInfo && recommendedFilmInfo._id;
                    this.$router.push({
                        query: { film: recommendedFilmInfoId, episode: 1 },
                    });
                }
            } catch (err) {
                console.log("getRecommendedEpisode err: ", err);
                this.$message.error(this.$t("messages.errors.defaultError"), 3);
            }
        },
        // 獲取喜愛列表
        async getFavoriteFilms() {
            try {
                const res = await videoAPI.getFavoriteFilms();
                if (res.status !== 200) {
                    throw new Error(res);
                } else {
                    console.log("getFavoriteFilms res: ", res);
                    const favoriteFilmList = res.data;
                    const idList = favoriteFilmList.map(
                        (filmInfo) => filmInfo._id,
                    );
                    this.favoriteFilms = idList || [];
                }
            } catch (err) {
                console.log("getFavoriteFilms err: ", err);
                this.$message.error(this.$t("messages.errors.defaultError"), 3);
            }
        },
        // 獲取本集影片資源
        async getEpisodeSource(filmId, episodeNumber) {
            try {
                const res = await videoAPI.getEpisodeSourceURL(
                    filmId,
                    episodeNumber,
                );
                if (res.status !== 200) {
                    throw new Error(res);
                } else {
                    console.log("getEpisodeSource res: ", res);
                    let videoSource = res.data;
                    this.currentEpisodeSource = videoSource;
                }
            } catch (err) {
                console.log("getEpisodeSource err: ", err);
                this.$message.error(this.$t("messages.errors.defaultError"), 3);
            }
        },
        // 獲取影集資訊
        async getFilmInfo(filmId) {
            try {
                const res = await videoAPI.getFilm(filmId);
                if (res.status !== 200) {
                    throw new Error(res);
                } else {
                    console.log("getFilmInfo res: ", res);
                    const filmInfo = res.data;
                    this.currentFilmInfo = filmInfo;
                    this.getFilmUnlockStatus(filmId);
                }
            } catch (err) {
                console.log("getFilmInfo err: ", err);
                this.$message.error(this.$t("messages.errors.defaultError"), 3);
            }
        },
        // 獲取當前劇集用戶解鎖狀態
        async getFilmUnlockStatus(filmId) {
            try {
                const res = await videoAPI.getFilmUnlockStatus(filmId);
                if (res.status !== 200) {
                    throw new Error(res);
                } else {
                    console.log("getFilmUnlockStatus res: ", res);
                    const unlockStatus = res.data;
                    this.userUnlockEpisodeStatus = unlockStatus;
                }
            } catch (err) {
                console.log("getFilmUnlockStatus err: ", err);
                this.$message.error(this.$t("messages.errors.defaultError"), 3);
            }
        },
        // 紀錄當前播放進度
        async recordCurrentEpisodeProgress() {
            try {
                const currentFilmId = this.$route.query.film;
                const currentEpisodeNumber = this.$route.query.episode;
                const currentVideoProgressSecond = Math.floor(
                    this.playProgressForDisplay,
                );
                const res = await videoAPI.saveEpisodeProgress({
                    showId: currentFilmId,
                    episode: currentEpisodeNumber,
                    second: currentVideoProgressSecond,
                });
                if (res.status !== 200) {
                    throw new Error(res);
                } else {
                    console.log("recordCurrentEpisodeProgress res: ", res);
                }
            } catch (err) {
                console.log("recordCurrentEpisodeProgress err: ", err);
            }
        },
        // 收藏影集
        async favoriteFilm(filmId) {
            this.isFavoriteActionChecking = true;
            try {
                const res = await videoAPI.favoriteFilm({
                    showId: filmId,
                });
                if (res.status !== 200) {
                    throw new Error(res);
                } else {
                    console.log("favoriteFilm res: ", res);
                    const favoriteFilms = res.data && res.data.favorites;
                    this.favoriteFilms = favoriteFilms;
                }
            } catch (err) {
                console.log("favoriteFilm err: ", err);
                this.favoriteFilms = this.favoriteFilms.filter(
                    (_filmId) => _filmId !== filmId,
                );
                this.$message.error(this.$t("messages.errors.defaultError"), 3);
            }
            this.isFavoriteActionChecking = false;
        },
        // 取消收藏影集
        async unfavoriteFilm(filmId) {
            this.isFavoriteActionChecking = true;
            try {
                const res = await videoAPI.unfavoriteFilm({
                    showId: filmId,
                });
                if (res.status !== 200) {
                    throw new Error(res);
                } else {
                    console.log("unfavoriteFilm res: ", res);
                    const favoriteFilms = res.data && res.data.favorites;
                    this.favoriteFilms = favoriteFilms;
                }
            } catch (err) {
                console.log("unfavoriteFilm err: ", err);
                this.favoriteFilms = [...this.favoriteFilms, filmId];
                this.$message.error(this.$t("messages.errors.defaultError"), 3);
            }
            this.isFavoriteActionChecking = false;
        },
        // 控制面板被點擊了
        panelOnClick() {
            // 如果本來就正在顯示中了，就直接關閉顯示並且清除關閉 timer
            if (this.showControlPanel) {
                this.showControlPanel = false;
                this.clearPanelHideTimer();
            } else {
                this.showControlPanel = true;
                // 如果暫停狀態下 切換介面顯示，就不觸發自動隱藏介面，而是持續顯示介面誘導用戶繼續播放或是點按操作
                if (this.isPaused) {
                    this.clearPanelHideTimer();
                } else {
                    this.initiatePanelHideTimer();
                }
            }
        },
        // 正在操作時不斷更新 timer 消失時間
        initiatePanelHideTimer() {
            clearTimeout(this.panelHideTimer);
            this.panelHideTimer = null;
            this.panelHideTimer = setTimeout(() => {
                this.showControlPanel = false;
            }, 5000);
        },
        // 清除隱藏面板用的計時器
        clearPanelHideTimer() {
            clearTimeout(this.panelHideTimer);
            this.panelHideTimer = null;
        },
        // 清除進度更新計時器
        clearVideoProgressRecorderTimer() {
            clearInterval(this.videoProgressRecorderTimer);
            this.videoProgressRecorderTimer = null;
        },

        // 切換是否暫停
        toggleIsPaused() {
            this.isPaused = !this.isPaused;
            // 若在播放中就重置 panel 關閉的 timer，若暫停中就直接清除 timer 誘導用戶點擊繼續播放或是進行其他操作
            if (this.isPaused) {
                this.clearPanelHideTimer();
            } else {
                this.initiatePanelHideTimer();
            }
        },
        // 進度條被用戶操作時觸發更新事件，將進度換算成秒單位更新給 video
        playProgressOnChange(value) {
            // 若在播放中就重置 panel 關閉的 timer
            if (!this.isPaused) {
                this.initiatePanelHideTimer();
            }
            const totalDuration = this.currentTotalSeconds;
            const permillageDecimal = new Decimal(value || 0);
            const newTime = permillageDecimal
                .dividedBy(1000)
                .times(totalDuration || 0);
            const newTimeNumber = Math.floor(Number(newTime));
            this.currentPlayingSeconds = newTimeNumber;
        },
        // 監測到 video 進度更新時，更新控制面板上面的進度條進度
        updateProgress(progressStatus) {
            const { currentTime, duration } = progressStatus;
            this.playProgressForDisplay = currentTime;
            this.currentTotalSeconds = duration;
        },
        // 監聽到選擇了新集數
        episodeSelected(episodeNumber) {
            const selectedFilmId =
                this.currentFilmInfo && this.currentFilmInfo._id;
            this.$router.push({
                query: { film: selectedFilmId, episode: episodeNumber },
            });
        },
        // 監聽到 video 加載完畢
        onEpisodeLoaded() {
            this.currentEpisodeStartTime = 0;
        },
        // 監聽到 video 開始播放
        onEpisodePlay() {
            this.isPaused = false;
            this.isVideoLoaded = true;
        },
        // 監聽到 video 加載中
        onEpisodeWaiting() {
            this.isWaiting = true;
        },
        // 監聽到 video 已經可以播放 (waiting 事件結束)
        onEpisodeCanPlay() {
            this.isWaiting = false;
        },
        // 監測到 video 播放完畢
        onEpisodeEnded() {
            console.log("episode ended");
            const currentFilmId = this.$route.query.film;
            const currentEpisodeNumber = Number(this.$route.query.episode);
            const unlockedEpisodeNumber = Number(
                this.currentFilmUnlockedEpisodeAmount,
            );
            const totalEpisodeNumber = Number(
                this.currentFilmTotalEpisodeAmount,
            );
            // 已經是最後一集，不能往後播了
            if (currentEpisodeNumber >= totalEpisodeNumber) {
                // TODO 跳出推薦劇集或是做其他操作
                return;
            }
            // 下一集沒有解鎖，彈出解鎖畫面
            if (currentEpisodeNumber + 1 > unlockedEpisodeNumber) {
                // 如果有打開自動解鎖，就不彈出解鎖畫面，直接就進行解鎖
                const localAutoUnlock = localStorage.getItem("autoUnlock");
                if (localAutoUnlock === "true") {
                    this.shouldAutoUnlock = true;
                    return;
                }
                this.isEpisodeUnlockPanelActive = true;
                return;
            }
            // 有下一集可以播，就直接跳下一集
            if (currentEpisodeNumber < unlockedEpisodeNumber) {
                this.$router.push({
                    query: {
                        film: currentFilmId,
                        episode: currentEpisodeNumber + 1,
                    },
                });
                return;
            }
        },
        // 開啟設定面板
        settingsBtnOnClick() {
            // 暫停影片、隱藏控制面板、清除 timer，開啟設定頁
            this.isPaused = true;
            // this.showControlPanel = false;
            this.clearPanelHideTimer();
            this.$store.commit("setIsSettingsShow", true);
        },
        // 用戶打算解鎖劇集
        unlockEpisode(unlockEpisodeInfo) {
            this.currentUnlockingEpisodeConfig = unlockEpisodeInfo;
            this.isEpisodeUnlockPanelActive = true;
        },
        // 當有劇集被解鎖時
        onEpisodeUnlocked() {
            // 關閉解鎖彈窗
            this.isEpisodeUnlockPanelActive = false;
            // 更新 selectEpisodePanel 解鎖進度
            this.shouldSelectEpisodePanelUpdateUnlockStatus = true;
            const currentFilmId = this.$route.query.film;
            this.getFilmUnlockStatus(currentFilmId);
            // 如果是自動解鎖並且沒有開啟選集介面，表示是觀劇中解鎖，那就直接播放下一集
            const localIsAutoUnlock = localStorage.getItem("autoUnlock");
            if (
                localIsAutoUnlock === "true" &&
                !this.isEpisodeSelectPanelActive
            ) {
                const currentFilmId = this.$route.query.film;
                const currentEpisodeNumber = Number(this.$route.query.episode);
                this.$router.push({
                    query: {
                        film: currentFilmId,
                        episode: currentEpisodeNumber + 1,
                    },
                });
            }
        },
        showComingSoon() {
            this.$message.warning(this.$t("messages.warning.comingSoon"), 1);
        },
        likeBtnOnClick() {
            this.showComingSoon();
        },
        listBtnOnClick() {
            const currentFilmId = this.$route.query.film;
            if (this.isCurrentFilmFavorite) {
                this.favoriteFilms = this.favoriteFilms.filter(
                    (_filmId) => _filmId !== currentFilmId,
                );
                this.unfavoriteFilm(currentFilmId);
            } else {
                this.favoriteFilms = [...this.favoriteFilms, currentFilmId];
                this.favoriteFilm(currentFilmId);
            }
        },
        shareBtnOnClick() {
            // 暫停影片、隱藏控制面板、清除 timer，開啟設定頁
            this.isPaused = true;
            // this.showControlPanel = false;
            this.clearPanelHideTimer();
            this.isSharePanelActive = true;
        },
        filmsBtnOnclick() {
            // 暫停影片、隱藏控制面板、清除 timer，開啟設定頁
            this.isPaused = true;
            // this.showControlPanel = false;
            this.clearPanelHideTimer();
            this.isFilmSelectPanelActive = true;
        },
        episodeBtnOnClick() {
            // 暫停影片、隱藏控制面板、清除 timer，開啟設定頁
            this.isPaused = true;
            // this.showControlPanel = false;
            this.clearPanelHideTimer();
            this.isEpisodeSelectPanelActive = true;
        },
        // 跳轉用戶頁面
        userBtnOnClick() {
            this.$router.push({ name: "my" });
        },
    },
    beforeDestroy() {
        this.clearPanelHideTimer();
        this.clearVideoProgressRecorderTimer();
        // 離開時紀錄一次
        this.recordCurrentEpisodeProgress();
    },
};
</script>

<style lang="scss">
.index-video-slider-wrapper {
    .ant-slider {
        margin-left: 0px !important;
        margin-right: 0px !important;
        display: flex;
        align-items: center;
    }

    .ant-slider-rail {
        border-radius: 0px;
        background: rgba($color: #fff, $alpha: 0.4) !important;
        height: 2px !important;
    }
    .ant-slider-track {
        background-color: #fff !important;
        height: 4px !important;
    }
    .ant-slider-handle {
        opacity: 0;
    }
}
</style>
