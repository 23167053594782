import { apiHelper } from "@/utils/requestHelper.js";

export default {
    getUserInfo() {
        return apiHelper.get("/user/login");
    },
    // 設定用戶性別
    setGender(gender) {
        return apiHelper.post("/user/setGender", { gender });
    },
    getUserAddress(token) {
        return apiHelper.get(`/user/getaddress?token=${token}`);
    },
};
