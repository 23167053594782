<template>
    <a-switch
        v-model="isChecked"
        :disabled="disabled"
        @change="switchOnChange"
    />
</template>

<script>
export default {
    name: "SwitchController",
    props: {
        controller: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isChecked: false,
    }),
    watch: {
        controller(newVal) {
            this.isChecked = newVal;
        },
    },
    methods: {
        switchOnChange(value) {
            this.$emit("update:controller", value);
        },
    },
    created() {
        this.isChecked = this.controller;
    },
};
</script>

<style lang="scss" scoped>
.ant-switch {
    background-color: #333;
}
.ant-switch-checked {
    background-color: #fc2956 !important;
}
</style>
