var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEpisodeUnlockPanelActive),expression:"isEpisodeUnlockPanelActive"}],staticClass:"fixed left-0 top-0 z-10 h-screen w-screen"},[_c('div',{staticClass:"absolute left-0 top-0 z-[-1] h-full w-full bg-[#000] bg-opacity-65",on:{"click":_vm.close}}),_c('div',{staticClass:"absolute bottom-0 left-0 flex w-full flex-col gap-5 rounded-t-xl bg-[#000] bg-opacity-65 p-6",staticStyle:{"backdrop-filter":"blur(10px)"}},[_c('button',{staticClass:"absolute right-3 top-3 h-7 w-7 opacity-15",on:{"click":_vm.close}},[_c('img',{staticClass:"h-full w-full",attrs:{"src":"assets/imgs/utils/icons/close_light.svg","alt":""}})]),_c('div',{staticClass:"flex w-full flex-col gap-1"},[_c('span',{staticClass:"text-2xl font-bold italic text-[#fff]"},[_vm._v(_vm._s(_vm.$t("views.index.episodeUnlockPanel.titleText")))]),_c('span',{staticClass:"text-xs text-[#fff] opacity-65"},[_vm._v(_vm._s(_vm.$t("views.index.episodeUnlockPanel.totalBalanceText", { balanceText: _vm.currentTotalBalanceText, })))])]),(_vm.isUserUnlockStatusLoading)?_c('div',{staticClass:"flex w-full flex-col gap-3"},_vm._l((3),function(i){return _c('div',{key:i,staticClass:"flex h-14 animate-pulse rounded-2xl bg-[#fff] bg-opacity-30 px-4"})}),0):_c('div',{staticClass:"flex w-full flex-col gap-3"},_vm._l((_vm.unlockOptions),function(option){return _c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isOptionDisabled(option)),expression:"!isOptionDisabled(option)"}],key:option.id,staticClass:"flex h-14 w-full items-center justify-between gap-5 rounded-2xl bg-[#fff] bg-opacity-15 px-4",class:{
                        '!border-2 !border-[#FC2956] !bg-opacity-[0.08]':
                            _vm.selectedOption &&
                            option.id === _vm.selectedOption.id,
                        '!opacity-30': _vm.isOptionDisabled(option),
                    },attrs:{"disabled":_vm.isOptionDisabled(option)},on:{"click":() => _vm.optionOnClick(option)}},[_c('span',{staticClass:"text-base font-bold text-[#fff]"},[_vm._v(_vm._s(_vm.$t( `views.index.episodeUnlockPanel.unlockOptionTitleText.${option.id}`, { unlockAmount: option.number }, )))]),_c('div',{staticClass:"flex flex-col items-end gap-[2px]"},[_c('span',{staticClass:"text-base font-bold italic text-[#FFBD14]"},[_vm._v(_vm._s(`${_vm.finalTotalPrice(option)} ${_vm.$t("tokenName.Coin")}`))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(option.off),expression:"option.off"}],staticClass:"flex items-center gap-2"},[_c('span',{staticClass:"text-xs italic text-[#fff] text-opacity-65",staticStyle:{"text-decoration":"line-through"}},[_vm._v(_vm._s(_vm.originalTotalPrice(option)))]),_c('span',{staticClass:"text-xs italic text-[#FC2956]"},[_vm._v(_vm._s(`-${option.off * 100}% OFF`))])])])])}),0),_c('div',{staticClass:"flex w-full items-center justify-start gap-2"},[_c('SwitchController',{attrs:{"controller":_vm.isAutoUnlock},on:{"update:controller":function($event){_vm.isAutoUnlock=$event}}}),_c('span',{staticClass:"text-xs font-bold text-[#fff]"},[_vm._v(_vm._s(_vm.$t("views.index.episodeUnlockPanel.autoUnlockText")))])],1),(_vm.isBalanceEnoughForSelectedOption)?_c('button',{staticClass:"flex h-14 w-full items-center justify-center rounded-full bg-[#FC2956] text-center text-xl font-bold italic text-[#fff]",class:{
                    'opacity-30': _vm.isLoading || _vm.isUserUnlockStatusLoading,
                },attrs:{"disabled":_vm.isLoading || _vm.isUserUnlockStatusLoading},on:{"click":_vm.unlockBtnOnClick}},[_vm._v(" "+_vm._s(_vm.$t("views.index.episodeUnlockPanel.unlockBtnText"))+" ")]):_c('button',{staticClass:"flex h-14 w-full items-center justify-center rounded-full bg-[#FC2956] text-center text-xl font-bold italic text-[#fff]",class:{
                    'opacity-30': _vm.isLoading || _vm.isUserUnlockStatusLoading,
                },attrs:{"disabled":_vm.isLoading || _vm.isUserUnlockStatusLoading},on:{"click":_vm.payBtnOnClick}},[_vm._v(" "+_vm._s(_vm.$t("views.index.episodeUnlockPanel.payBtnText"))+" ")])]),_c('EpisodePaymentPanel',{attrs:{"isEpisodePaymentPanelActive":_vm.isEpisodePaymentPanelActive,"requiredPaymentAmount":_vm.currentSelectedOptionPaymentRequiredAmount},on:{"close":function($event){_vm.isEpisodePaymentPanelActive = false},"paymentOnSuccess":_vm.handlePaymentOnSuccess}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }