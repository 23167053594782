var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEpisodeSelectPanelActive),expression:"isEpisodeSelectPanelActive"}],staticClass:"fixed left-0 top-0 z-10 h-screen w-screen"},[_c('div',{staticClass:"absolute left-0 top-0 z-[-1] h-full w-full bg-[#000] bg-opacity-65",on:{"click":_vm.close}}),_c('div',{staticClass:"absolute bottom-0 left-0 flex h-[320px] w-full flex-col gap-5 rounded-t-xl bg-[#000] bg-opacity-65 p-6",staticStyle:{"backdrop-filter":"blur(10px)"}},[_c('button',{staticClass:"absolute right-3 top-3 h-7 w-7 opacity-15",on:{"click":_vm.close}},[_c('img',{staticClass:"h-full w-full",attrs:{"src":"assets/imgs/utils/icons/close_light.svg","alt":""}})]),_c('div',{staticClass:"flex w-full flex-col gap-1"},[_c('span',{staticClass:"text-2xl font-bold italic text-[#fff]"},[_vm._v(_vm._s(_vm.filmName))]),_c('span',{staticClass:"text-xs text-[#fff]"},[_vm._v(_vm._s(_vm.$t("views.index.episodeSelectPanel.episodeAmountText", { episodeAmount: _vm.filmTotalEpisodeAmount, })))])]),_c('div',{staticClass:"no-scrollbar w-full overflow-scroll"},[(_vm.isLoading)?_c('div',{staticClass:"flex w-full flex-wrap gap-2"},_vm._l((12),function(i){return _c('div',{key:i,staticClass:"relative flex h-10 w-[calc(25%-6px)] animate-pulse items-center justify-center overflow-hidden rounded-xl bg-[#fff] bg-opacity-25"})}),0):_c('div',{staticClass:"flex w-full flex-wrap gap-2"},_vm._l((_vm.filmTotalEpisodeAmount),function(i){return _c('button',{key:i,staticClass:"relative flex h-10 w-[calc(25%-6px)] items-center justify-center overflow-hidden rounded-xl bg-[#fff] bg-opacity-25",class:{
                            '!bg-opacity-100': _vm.isEpisodeCurrentPlaying(i),
                            '!opacity-30': _vm.isUnlockStatusLoading,
                        },attrs:{"disabled":_vm.isEpisodeCurrentPlaying(i) ||
                            _vm.isUnlockStatusLoading},on:{"click":() => _vm.episodeBtnOnClick(i)}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(i > _vm.filmUnlockedEpisodeAmount),expression:"i > filmUnlockedEpisodeAmount"}],staticClass:"absolute right-0 top-0 z-0 flex h-5 w-5 items-center justify-center rounded-bl-xl bg-[#FC2956] text-center"},[_c('svg-icon',{staticClass:"h-3 w-3 text-[#fff]",attrs:{"type":"mdi","path":_vm.mdiLock}})],1),_c('span',{staticClass:"relative z-10 text-center text-sm font-bold text-[#fff]",class:{
                                '!text-[#FC2956]':
                                    _vm.isEpisodeCurrentPlaying(i),
                            }},[_vm._v(_vm._s(i))]),_c('svg-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEpisodeCurrentPlaying(i)),expression:"isEpisodeCurrentPlaying(i)"}],staticClass:"h-4 w-4 text-[#FC2956]",attrs:{"type":"mdi","path":_vm.mdiPlay}})],1)}),0)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }