<template>
    <transition name="fade">
        <div
            v-show="isEpisodePaymentPanelActive"
            class="fixed left-0 top-0 z-10 h-screen w-screen"
        >
            <!-- overlay -->
            <div
                @click="close"
                class="absolute left-0 top-0 z-[-1] h-full w-full bg-[#000] bg-opacity-65"
            ></div>
            <!-- panel content -->
            <div
                class="absolute bottom-0 left-0 flex w-full flex-col gap-5 rounded-t-xl bg-[#000] bg-opacity-65 p-6"
                style="backdrop-filter: blur(10px)"
            >
                <!-- close button -->
                <button
                    @click="close"
                    class="absolute right-3 top-3 h-7 w-7 opacity-15"
                >
                    <img
                        src="assets/imgs/utils/icons/close_light.svg"
                        alt=""
                        class="h-full w-full"
                    />
                </button>
                <!-- title row -->
                <div class="flex w-full flex-col gap-1">
                    <!-- title text -->
                    <span class="text-2xl font-bold italic text-[#fff]">{{
                        $t("views.index.episodePaymentPanel.titleText", {
                            requiredAmount: requiredPaymentAmount,
                        })
                    }}</span>
                    <!-- balance text -->
                    <span class="text-xs text-[#fff] opacity-65">{{
                        $t("views.index.episodePaymentPanel.totalBalanceText", {
                            balanceText: currentTotalBalanceText,
                        })
                    }}</span>
                </div>
                <!-- skeleton products -->
                <div
                    v-if="isLoading"
                    class="flex w-full flex-wrap gap-x-3 gap-y-4"
                >
                    <!-- skeleton product -->
                    <div
                        v-for="i in 6"
                        :key="i"
                        class="h-[100px] w-[calc(33.333%-8px)] animate-pulse rounded-xl bg-[#fff] bg-opacity-25"
                    ></div>
                </div>
                <!-- products wrapper -->
                <div v-else class="flex w-full flex-wrap gap-x-3 gap-y-4">
                    <!-- product -->
                    <button
                        v-for="product in availableProducts"
                        :key="product._id"
                        :disabled="!product.active"
                        @click="() => productOnClick(product)"
                        class="h-[100px] w-[calc(33.333%-8px)] overflow-hidden rounded-xl bg-[#fff] bg-opacity-25"
                        :class="{
                            '!border !border-[#FC2956] !bg-opacity-0':
                                currentSelectedProduct._id === product._id,
                            '!opacity-30': !product.active,
                        }"
                    >
                        <!-- upper content -->
                        <div
                            class="flex h-[72px] w-full flex-col items-center justify-center"
                        >
                            <span
                                class="text-center text-xl font-bold italic text-[#fff]"
                                >{{ productCoinAmount(product) }}</span
                            >
                            <span
                                class="text-center text-xs italic text-[#FC2956]"
                                >{{
                                    `+${productBonusAmount(product)} ${$t("tokenName.Bonus")}`
                                }}</span
                            >
                        </div>
                        <!-- lower content -->
                        <div
                            class="flex h-7 w-full items-center justify-center bg-[#000] bg-opacity-15"
                            :class="{
                                '!bg-[#FC2956] !bg-opacity-100':
                                    currentSelectedProduct._id === product._id,
                            }"
                        >
                            <span
                                class="text-center text-xs font-bold text-[#FFBD14]"
                                :class="{
                                    '!text-[#fff]':
                                        currentSelectedProduct._id ===
                                        product._id,
                                }"
                                >{{ productPriceText(product) }}</span
                            >
                        </div>
                    </button>
                </div>
                <!-- payment method select row -->
                <div class="flex w-full justify-end">
                    <!-- payment method selector -->
                    <Dropdown
                        v-if="currentSelectedPaymentMethod"
                        @optionOnclick="paymentMethodSelected"
                        :showMenu.sync="showPaymentMethodsDropdown"
                        :items="paymentMethodDropdownItems"
                    >
                        <template #toggle-button>
                            <button class="flex h-4 items-center gap-1">
                                <img
                                    :src="`assets/imgs/views/store/payment_${currentSelectedPaymentMethod.id}.png`"
                                    class="h-4 w-4 overflow-hidden rounded-full"
                                    alt=""
                                />
                                <span class="text-xs font-bold text-[#fff]">{{
                                    $t(
                                        `views.store.paymentMethodTitleText.${currentSelectedPaymentMethod.id}`,
                                    )
                                }}</span>
                                <svg-icon
                                    type="mdi"
                                    :path="mdiMenuDown"
                                    class="h-4 w-4 text-[#fff]"
                                ></svg-icon>
                            </button>
                        </template>
                    </Dropdown>
                </div>
                <!-- confirm pay button -->
                <button
                    @click="payBtnOnClick"
                    :disabled="isLoading"
                    class="flex h-14 w-full flex-shrink-0 items-center justify-center rounded-full bg-[#FC2956] text-center text-xl font-bold italic text-[#fff]"
                    :class="{ '!opacity-30': isLoading }"
                >
                    {{
                        $t("views.index.episodePaymentPanel.payBtnText", {
                            priceText: productPriceText(currentSelectedProduct),
                        })
                    }}
                </button>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapGetters } from "vuex";
import Decimal from "decimal.js";
import { getPrecisionValue } from "@/utils/textFormat";
import storeAPI from "@/apis/store";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiMenuDown } from "@mdi/js";
import Dropdown from "@/components/Dropdown.vue";

export default {
    name: "EpisodePaymentPanel",
    components: {
        Dropdown,
        SvgIcon,
    },
    props: {
        isEpisodePaymentPanelActive: {
            type: Boolean,
            default: false,
        },
        requiredPaymentAmount: {
            default: 0,
        },
    },
    data: () => ({
        mdiMenuDown,
        showPaymentMethodsDropdown: false,
        isLoading: false,
        currentSelectedPaymentMethod: null,
        currentPaymentMethodProducts: null,
        currentSelectedProduct: null,
        paymentMethods: [
            {
                id: "stars",
                active: true,
            },
            {
                id: "tribute",
                active: false,
            },
            {
                id: "wallet",
                active: false,
            },
        ],
        // 是否正在輪詢確認餘額
        isCheckingBalance: false,
        // timer
        userBalanceUpdateTimer: null,
    }),
    computed: {
        ...mapGetters(["currentCoinBalance", "currentBonusBalance"]),
        paymentMethodDropdownItems() {
            const activePaymentMethods =
                this.paymentMethods &&
                this.paymentMethods.filter((method) => method.active);
            return (
                activePaymentMethods &&
                activePaymentMethods.map((method) => {
                    return {
                        id: method.id,
                        text: this.$t(
                            `views.index.episodePaymentPanel.paymentMethodTitleText.${method.id}`,
                        ),
                        imgPath: `assets/imgs/views/store/payment_${method.id}.png`,
                    };
                })
            );
        },
        currentTotalBalance() {
            const currentCoinBalanceDecimal = new Decimal(
                this.currentCoinBalance || 0,
            );
            const currentBonusBalanceDecimal = new Decimal(
                this.currentBonusBalance || 0,
            );
            const totalBalanceDecimal = currentCoinBalanceDecimal.plus(
                currentBonusBalanceDecimal || 0,
            );
            return totalBalanceDecimal;
        },
        currentTotalBalanceText() {
            const coinAmountText = getPrecisionValue(
                "Coin",
                this.currentCoinBalance,
            );
            const coinTokenName = this.$t("tokenName.Coin");
            const bonusAmountText = getPrecisionValue(
                "Bonus",
                this.currentBonusBalance,
            );
            const bonusTokenName = this.$t("tokenName.Bonus");
            if (!this.currentCoinBalance && !this.currentBonusBalance) {
                return "0";
            }
            return `${this.currentCoinBalance ? `${coinAmountText} ${coinTokenName}` : ""}${this.currentCoinBalance && this.currentBonusBalance ? " + " : ""}${this.currentBonusBalance ? `${bonusAmountText} ${bonusTokenName}` : ""}`;
        },
        // 能夠滿足當前付費需求金額的方案
        availableProducts() {
            const allProducts = this.currentPaymentMethodProducts || [];
            const availableProducts = allProducts.filter((product) => {
                const productCoinConfig =
                    product.tokens &&
                    product.tokens.find((token) => token.token === "Coin");
                const productBonusConfig =
                    product.tokens &&
                    product.tokens.find((token) => token.token === "Bonus");
                const productCoinAmountDecimal =
                    productCoinConfig &&
                    productCoinConfig.amount &&
                    new Decimal(productCoinConfig.amount || 0);
                const productBonusAmountDecimal =
                    productBonusConfig &&
                    productBonusConfig.amount &&
                    new Decimal(productBonusConfig.amount || 0);
                const productTotalValueDecimal = productCoinAmountDecimal.plus(
                    productBonusAmountDecimal || 0,
                );
                const currentTotalBalanceDecimal = this.currentTotalBalance;
                const requiredPaymentAmountDecimal =
                    this.requiredPaymentAmount &&
                    new Decimal(this.requiredPaymentAmount || 0);
                return productTotalValueDecimal
                    .plus(currentTotalBalanceDecimal || 0)
                    .greaterThanOrEqualTo(requiredPaymentAmountDecimal);
            });
            return availableProducts;
        },
    },
    watch: {
        // 開啟時獲取用戶解鎖進度
        isEpisodePaymentPanelActive(newVal) {
            this.updateBalance();
            this.initiatePaymentMethods();
            if (!newVal) {
                this.destroyUpdateUserBalanceTimer();
            }
        },
        // 更新解鎖進度的 hook 被觸發時進行更新
        shouldSelectEpisodePanelUpdateUnlockStatus(newVal) {
            if (newVal) {
                this.getFilmUnlockStatus();
                // 重置 hook
                this.$emit(
                    "update:shouldSelectEpisodePanelUpdateUnlockStatus",
                    false,
                );
            }
        },
        // 選擇支付方式時獲取支付產品
        currentSelectedPaymentMethod(newVal) {
            if (newVal) {
                const paymentMethodId = newVal.id;
                if (paymentMethodId === "stars") {
                    this.getStarsProducts();
                }
                // TODO 其他支付方法再看看怎麼接
            }
        },
        // 當有新的可行產品傳入時，把預選選項設為第一個可行產品
        availableProducts(newVal) {
            if (newVal && !!newVal.length) {
                this.currentSelectedProduct = newVal[0];
            }
        },
        currentTotalBalance(newVal) {
            if (newVal && this.isCheckingBalance) {
                const currentTotalBalanceDecimal = new Decimal(newVal || 0);
                const requiredPaymentAmountDecimal =
                    this.requiredPaymentAmount &&
                    new Decimal(this.requiredPaymentAmount || 0);
                if (
                    currentTotalBalanceDecimal.greaterThanOrEqualTo(
                        requiredPaymentAmountDecimal,
                    )
                ) {
                    this.isCheckingBalance = false;
                    this.destroyUpdateUserBalanceTimer();
                    this.$emit("paymentOnSuccess");
                }
            }
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        async updateBalance() {
            try {
                const err = await this.$store.dispatch("updateUserInfo");
                if (err) {
                    throw new Error(JSON.stringify(err));
                }
            } catch (err) {
                this.$message.error($t("messages.errors.defaultError"), 3);
            }
        },
        async getStarsProducts() {
            this.isLoading = true;
            try {
                const res = await storeAPI.getProducts();
                if (res.status !== 200) {
                    throw new Error(res);
                } else {
                    console.log("getProducts res: ", res);
                    const products = res.data;
                    this.currentPaymentMethodProducts = products;
                }
            } catch (err) {
                console.log("getProducts err: ", err);
            }
            this.isLoading = false;
        },
        initiatePaymentMethods() {
            // 先看看本地端有沒有存取上一次支付的支付方法
            const localPaymentMethod = localStorage.getItem("paymentMethod");
            if (localPaymentMethod) {
                const targetPaymentMethod = this.paymentMethods.find(
                    (method) => method.id === localPaymentMethod,
                );
                if (targetPaymentMethod) {
                    this.currentSelectedPaymentMethod = targetPaymentMethod;
                } else {
                    // 沒有的話默認選第一個支付方法
                    this.currentSelectedPaymentMethod = this.paymentMethods[0];
                }
            } else {
                // 沒有的話默認選第一個支付方法
                this.currentSelectedPaymentMethod = this.paymentMethods[0];
            }
        },
        paymentMethodSelected(paymentOptionItem) {
            const { id } = paymentOptionItem;
            const paymentMethodId = id;
            const targetPaymentMethod =
                this.paymentMethods &&
                this.paymentMethods.find(
                    (method) => method.id === paymentMethodId,
                );
            if (targetPaymentMethod) {
                this.currentSelectedPaymentMethod = targetPaymentMethod;
            }
        },
        productCoinAmount(product) {
            const coinConfig =
                product &&
                product.tokens &&
                product.tokens.find((token) => token.token === "Coin");
            return coinConfig ? coinConfig.amount : 0;
        },
        productBonusAmount(product) {
            const bonusConfig =
                product &&
                product.tokens &&
                product.tokens.find((token) => token.token === "Bonus");
            return bonusConfig ? bonusConfig.amount : 0;
        },
        productPriceText(product) {
            const isStarsProduct = product && product.starsNumber;
            return isStarsProduct ? `${product.starsNumber} Stars` : "-";
        },
        productOnClick(product) {
            this.currentSelectedProduct = product;
        },
        savePaymentMethodToLocal() {
            const paymentMethod =
                this.currentSelectedProduct && this.currentSelectedProduct.id;
            if (paymentMethod) {
                localStorage.setItem("paymentMethod", paymentMethod);
            }
        },
        // 每三秒輪詢一次更新餘額
        pollingUpdateUserBalance() {
            this.userBalanceUpdateTimer = setInterval(() => {
                this.updateBalance();
            }, 3000);
        },
        // 消滅計時器
        destroyUpdateUserBalanceTimer() {
            clearInterval(this.userBalanceUpdateTimer);
            this.userBalanceUpdateTimer = null;
        },
        payBtnOnClick() {
            const isCurrentSelectedProductStarsProduct =
                this.currentSelectedProduct &&
                this.currentSelectedProduct.starsNumber;
            if (isCurrentSelectedProductStarsProduct) {
                // active deep-link
                const productDeepLink =
                    this.currentSelectedProduct &&
                    this.currentSelectedProduct.invoiceLink;
                window.open(productDeepLink);
                // 開始輪詢更新餘額
                this.isCheckingBalance = true;
                this.pollingUpdateUserBalance();
            }
            this.savePaymentMethodToLocal();
        },
    },
    beforeDestroy() {
        this.destroyUpdateUserBalanceTimer();
    },
};
</script>
