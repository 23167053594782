<template>
    <div
        class="flex items-center justify-center"
        :class="{ 'fixed left-0 top-0 h-screen w-screen': fullScreen }"
    >
        <LottieAnimation
            :animationData="require('/public/assets/lottie/shortv.json')"
            :width="'100px'"
            :height="'100px'"
        />
    </div>
</template>

<script>
import LottieAnimation from "@/components/LottieAnimation.vue";

export default {
    name: "Loading",
    components: {
        LottieAnimation,
    },
    props: {
        fullScreen: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
