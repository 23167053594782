import store from "@/store";
import Decimal from "decimal.js";

// export function tokenAmountTextFormat(balance) {
//     const balanceNum = Number(balance);
//     // // NaN
//     // if (isNaN(balanceNum)) return "0";
//     // // over 1M
//     // if (balanceNum >= 1000000) {
//     //     // 9876|543000 -> 9.8765M
//     //     return `${Number(
//     //         Math.floor((balance * 100) / 1000000) / 100,
//     //     ).toLocaleString("en-US", {
//     //         maximumFractionDigits: 2,
//     //         minimumFractionDigits: 2,
//     //     })}M`;
//     // } else if (balanceNum >= 1000) {
//     //     // 9876543|000 -> 9876543.000K
//     //     return `${Number(
//     //         Math.floor((balance * 100) / 1000) / 100,
//     //     ).toLocaleString("en-US", {
//     //         maximumFractionDigits: 2,
//     //         minimumFractionDigits: 2,
//     //     })}K`;
//     // } else {
//     //     return `${Number(
//     //         Math.floor((balance * 10000) / 1) / 10000,
//     //     ).toLocaleString("en-US", {
//     //         maximumFractionDigits: 4,
//     //         minimumFractionDigits: 2,
//     //     })}`;
//     // }
//     return `${Number(Math.floor((balance * 10000) / 1) / 10000).toLocaleString(
//         "en-US",
//         {
//             maximumFractionDigits: 3,
//             minimumFractionDigits: 2,
//         },
//     )}`;
// }

export function getPrecisionValue(tokenId, value = 0) {
    const tokenList = store.getters.tokenList;
    const token = tokenList && tokenList.find((token) => token._id === tokenId);

    if (!token) {
        return value;
    }

    const precision = token.tokenPrecision;

    // 处理不同类型的数值
    let preciseValue = new Decimal(value).toFixed(
        precision,
        Decimal.ROUND_DOWN,
    );
    // if (typeof value === "number") {
    //     preciseValue = parseFloat(value.toFixed(precision));
    // } else if (typeof value === "string") {
    //     preciseValue = parseFloat(parseFloat(value).toFixed(precision));
    // } else if (value instanceof Decimal) {
    //     preciseValue = parseFloat(value.toFixed(precision).toString());
    // }

    return Number(preciseValue).toLocaleString("en-US");
}

export function bloodlineTextFormat(bloodline) {
    if (typeof bloodline !== "string" || bloodline.trim() === "") {
        return "";
    }
    return `${bloodline.charAt(0).toUpperCase()}${bloodline
        .slice(1)
        .toLowerCase()}`;
}
