<template>
    <transition name="fade">
        <div
            v-show="isEpisodeUnlockPanelActive"
            class="fixed left-0 top-0 z-10 h-screen w-screen"
        >
            <!-- overlay -->
            <div
                @click="close"
                class="absolute left-0 top-0 z-[-1] h-full w-full bg-[#000] bg-opacity-65"
            ></div>
            <!-- panel content -->
            <div
                class="absolute bottom-0 left-0 flex w-full flex-col gap-5 rounded-t-xl bg-[#000] bg-opacity-65 p-6"
                style="backdrop-filter: blur(10px)"
            >
                <!-- close button -->
                <button
                    @click="close"
                    class="absolute right-3 top-3 h-7 w-7 opacity-15"
                >
                    <img
                        src="assets/imgs/utils/icons/close_light.svg"
                        alt=""
                        class="h-full w-full"
                    />
                </button>
                <!-- title row -->
                <div class="flex w-full flex-col gap-1">
                    <!-- title text -->
                    <span class="text-2xl font-bold italic text-[#fff]">{{
                        $t("views.index.episodeUnlockPanel.titleText")
                    }}</span>
                    <!-- balance text -->
                    <span class="text-xs text-[#fff] opacity-65">{{
                        $t("views.index.episodeUnlockPanel.totalBalanceText", {
                            balanceText: currentTotalBalanceText,
                        })
                    }}</span>
                </div>
                <!-- unlock options skeleton loader -->
                <div
                    v-if="isUserUnlockStatusLoading"
                    class="flex w-full flex-col gap-3"
                >
                    <div
                        v-for="i in 3"
                        :key="i"
                        class="flex h-14 animate-pulse rounded-2xl bg-[#fff] bg-opacity-30 px-4"
                    ></div>
                </div>
                <!-- unlock options -->
                <div v-else class="flex w-full flex-col gap-3">
                    <!-- option button -->
                    <button
                        v-for="option in unlockOptions"
                        :key="option.id"
                        :disabled="isOptionDisabled(option)"
                        @click="() => optionOnClick(option)"
                        v-show="!isOptionDisabled(option)"
                        class="flex h-14 w-full items-center justify-between gap-5 rounded-2xl bg-[#fff] bg-opacity-15 px-4"
                        :class="{
                            '!border-2 !border-[#FC2956] !bg-opacity-[0.08]':
                                selectedOption &&
                                option.id === selectedOption.id,
                            '!opacity-30': isOptionDisabled(option),
                        }"
                    >
                        <!-- option title text -->
                        <span class="text-base font-bold text-[#fff]">{{
                            $t(
                                `views.index.episodeUnlockPanel.unlockOptionTitleText.${option.id}`,
                                { unlockAmount: option.number },
                            )
                        }}</span>
                        <!-- option content wrapper -->
                        <div class="flex flex-col items-end gap-[2px]">
                            <!-- price -->
                            <span
                                class="text-base font-bold italic text-[#FFBD14]"
                                >{{
                                    `${finalTotalPrice(option)} ${$t("tokenName.Coin")}`
                                }}</span
                            >
                            <!-- discount wrapper -->
                            <div
                                v-show="option.off"
                                class="flex items-center gap-2"
                            >
                                <!-- original price -->
                                <span
                                    class="text-xs italic text-[#fff] text-opacity-65"
                                    style="text-decoration: line-through"
                                    >{{ originalTotalPrice(option) }}</span
                                >
                                <span class="text-xs italic text-[#FC2956]">{{
                                    `-${option.off * 100}% OFF`
                                }}</span>
                            </div>
                        </div>
                    </button>
                </div>
                <!-- auto unlock row -->
                <div class="flex w-full items-center justify-start gap-2">
                    <!-- auto unlock switch -->
                    <SwitchController :controller.sync="isAutoUnlock" />
                    <!-- title text -->
                    <span class="text-xs font-bold text-[#fff]">{{
                        $t("views.index.episodeUnlockPanel.autoUnlockText")
                    }}</span>
                </div>
                <!-- unlock button -->
                <button
                    v-if="isBalanceEnoughForSelectedOption"
                    @click="unlockBtnOnClick"
                    :disabled="isLoading || isUserUnlockStatusLoading"
                    class="flex h-14 w-full items-center justify-center rounded-full bg-[#FC2956] text-center text-xl font-bold italic text-[#fff]"
                    :class="{
                        'opacity-30': isLoading || isUserUnlockStatusLoading,
                    }"
                >
                    {{ $t("views.index.episodeUnlockPanel.unlockBtnText") }}
                </button>
                <!-- pay now button -->
                <button
                    v-else
                    @click="payBtnOnClick"
                    :disabled="isLoading || isUserUnlockStatusLoading"
                    class="flex h-14 w-full items-center justify-center rounded-full bg-[#FC2956] text-center text-xl font-bold italic text-[#fff]"
                    :class="{
                        'opacity-30': isLoading || isUserUnlockStatusLoading,
                    }"
                >
                    {{ $t("views.index.episodeUnlockPanel.payBtnText") }}
                </button>
            </div>
            <EpisodePaymentPanel
                @close="isEpisodePaymentPanelActive = false"
                @paymentOnSuccess="handlePaymentOnSuccess"
                :isEpisodePaymentPanelActive="isEpisodePaymentPanelActive"
                :requiredPaymentAmount="
                    currentSelectedOptionPaymentRequiredAmount
                "
            />
        </div>
    </transition>
</template>

<script>
import { mapGetters } from "vuex";
import Decimal from "decimal.js";
import { getPrecisionValue } from "@/utils/textFormat";
import videoAPI from "@/apis/video";
import SwitchController from "@/components/SwitchController.vue";
import EpisodePaymentPanel from "./EpisodePaymentPanel.vue";

export default {
    name: "EpisodeUnlockPanel",
    components: {
        SwitchController,
        EpisodePaymentPanel,
    },
    props: {
        isEpisodeUnlockPanelActive: {
            type: Boolean,
            default: false,
        },
        filmInfo: {
            type: Object,
            default: null,
        },
        shouldAutoUnlock: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isLoading: false,
        isEpisodePaymentPanelActive: false,
        isUserUnlockStatusLoading: false,
        isAutoUnlock: true,
        userUnlockEpisodeStatus: null,
        selectedOption: null,
    }),
    computed: {
        ...mapGetters(["currentCoinBalance", "currentBonusBalance"]),
        currentTotalBalanceText() {
            const coinAmountText = getPrecisionValue(
                "Coin",
                this.currentCoinBalance,
            );
            const coinTokenName = this.$t("tokenName.Coin");
            const bonusAmountText = getPrecisionValue(
                "Bonus",
                this.currentBonusBalance,
            );
            const bonusTokenName = this.$t("tokenName.Bonus");
            if (!this.currentCoinBalance && !this.currentBonusBalance) {
                return "0";
            }
            return `${this.currentCoinBalance ? `${coinAmountText} ${coinTokenName}` : ""}${this.currentCoinBalance && this.currentBonusBalance ? " + " : ""}${this.currentBonusBalance ? `${bonusAmountText} ${bonusTokenName}` : ""}`;
        },
        unlockOptions() {
            return this.filmInfo && this.filmInfo.unlockConfig;
        },
        filmTotalEpisodeAmount() {
            return (
                this.filmInfo &&
                this.filmInfo.episodes &&
                this.filmInfo.episodes.totalNumber
            );
        },
        filmUnlockedEpisodeAmount() {
            const filmDefaultUnlockNumber =
                this.filmInfo &&
                this.filmInfo.episodes &&
                this.filmInfo.episodes.unlockNumber;
            const userUnlockNumber =
                this.userUnlockEpisodeStatus &&
                this.userUnlockEpisodeStatus.unlockNumber;
            return userUnlockNumber || filmDefaultUnlockNumber;
        },
        filmLockedEpisodeAmount() {
            return this.filmTotalEpisodeAmount - this.filmUnlockedEpisodeAmount;
        },
        // 當前所選方案需要支付的金額
        currentSelectedOptionPaymentRequiredAmount() {
            return (
                this.selectedOption && this.finalTotalPrice(this.selectedOption)
            );
        },
        // 用戶當前餘額是否足夠支付所選方案
        isBalanceEnoughForSelectedOption() {
            const selectedOptionPrice =
                this.selectedOption &&
                this.finalTotalPrice(this.selectedOption);
            const currentCoinBalanceDecimal = new Decimal(
                this.currentCoinBalance || 0,
            );
            const currentBonusBalanceDecimal = new Decimal(
                this.currentBonusBalance || 0,
            );
            const currentTotalBalanceDecimal = currentCoinBalanceDecimal.plus(
                currentBonusBalanceDecimal,
            );
            return currentTotalBalanceDecimal.greaterThanOrEqualTo(
                selectedOptionPrice || 0,
            );
        },
        // 用戶當前餘額是否足夠支付自動解鎖的方案
        isBalanceEnoughForAutoUnlock() {
            const autoUnlockOption =
                this.unlockOptions &&
                this.unlockOptions.find((option) => option.id === 1);

            const autoUnlockOptionPrice =
                autoUnlockOption && this.finalTotalPrice(autoUnlockOption);
            const currentCoinBalanceDecimal = new Decimal(
                this.currentCoinBalance || 0,
            );
            const currentBonusBalanceDecimal = new Decimal(
                this.currentBonusBalance || 0,
            );
            const currentTotalBalanceDecimal = currentCoinBalanceDecimal.plus(
                currentBonusBalanceDecimal,
            );
            return currentTotalBalanceDecimal.greaterThanOrEqualTo(
                autoUnlockOptionPrice || 0,
            );
        },
    },
    watch: {
        // 開啟時更新用戶餘額
        isEpisodeUnlockPanelActive(newVal) {
            if (newVal) {
                this.getLocalAutoUnlock();
                this.updateBalance();
                this.getFilmUnlockStatus();
            }
        },
        // 預設選擇第一個選項
        unlockOptions(newVal) {
            if (newVal) {
                const firstOption = newVal[0];
                this.selectedOption = firstOption;
            }
        },
        // 自動解鎖 hook 被觸發時進行自動解鎖
        shouldAutoUnlock(newVal) {
            if (newVal) {
                if (this.isBalanceEnoughForAutoUnlock) {
                    // 使用單集解鎖方式進行解鎖
                    this.unlockEpisodes(1);
                } else {
                    // 餘額不足的話就拉起解鎖介面
                    this.$emit("update:isEpisodeUnlockPanelActive", true);
                }
                // 重置 hook
                this.$emit("update:shouldAutoUnlock", false);
            }
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        getLocalAutoUnlock() {
            const localAutoUnlock = localStorage.getItem("autoUnlock");
            if (localAutoUnlock && localAutoUnlock === "false") {
                this.isAutoUnlock = false;
            } else {
                this.isAutoUnlock = true;
            }
        },
        async updateBalance() {
            this.isLoading = true;
            try {
                const err = await this.$store.dispatch("updateUserInfo");
                if (err) {
                    throw new Error(JSON.stringify(err));
                }
            } catch (err) {
                this.$message.error(this.$t("messages.errors.defaultError"), 3);
            }
            this.isLoading = false;
        },
        // 獲取當前劇集用戶解鎖狀態
        async getFilmUnlockStatus() {
            this.isUserUnlockStatusLoading = true;
            try {
                const filmId = this.filmInfo && this.filmInfo._id;
                const res = await videoAPI.getFilmUnlockStatus(filmId);
                if (res.status !== 200) {
                    throw new Error(res);
                } else {
                    console.log("getFilmUnlockStatus res: ", res);
                    const unlockStatus = res.data;
                    this.userUnlockEpisodeStatus = unlockStatus;
                }
            } catch (err) {
                console.log("getFilmUnlockStatus err: ", err);
                this.$message.error(this.$t("messages.errors.defaultError"), 3);
            }
            this.isUserUnlockStatusLoading = false;
        },
        finalTotalPrice(option) {
            const originalEpisodePriceDecimal =
                option && option.price && new Decimal(option.price || 0);
            const priceOff =
                option && option.off && new Decimal(option.off || 0);
            const currentLockedEpisodeAmount = this.filmLockedEpisodeAmount;
            const unlockAmount =
                (option && option.number && new Decimal(option.number || 0)) ||
                new Decimal(currentLockedEpisodeAmount || 0);
            const discount = new Decimal(1).minus(priceOff || 0);
            return originalEpisodePriceDecimal
                .times(discount || 0)
                .times(unlockAmount)
                .ceil();
        },
        originalTotalPrice(option) {
            const originalEpisodePriceDecimal =
                option && option.price && new Decimal(option.price || 0);
            const currentLockedEpisodeAmount = this.filmLockedEpisodeAmount;
            const unlockAmount =
                (option && option.number && new Decimal(option.number || 0)) ||
                new Decimal(currentLockedEpisodeAmount || 0);
            return originalEpisodePriceDecimal.times(unlockAmount).ceil();
        },
        isOptionDisabled(option) {
            const optionId = option && option.id;
            // 方案一： 解鎖單集無論如何都可以選
            if (optionId === 1) {
                return false;
            }
            // 方案二： 剩餘鎖定集數 * 1.5 >= 此方案解鎖級數 才可以選
            if (optionId === 2) {
                const currentLockedEpisodeAmount = this.filmLockedEpisodeAmount;
                const optionUnlockEpisodeAmount = option && option.number;
                if (
                    currentLockedEpisodeAmount * 1.5 >=
                    optionUnlockEpisodeAmount
                ) {
                    return false;
                } else {
                    return true;
                }
            }

            // 方案三：只要剩餘級數 > 1 就可以選
            if (optionId === 3) {
                const currentLockedEpisodeAmount = this.filmLockedEpisodeAmount;
                if (currentLockedEpisodeAmount > 1) {
                    return false;
                } else {
                    return true;
                }
            }
        },
        // 解鎖劇集
        async unlockEpisodes(optionId) {
            this.isLoading = true;
            try {
                const filmId = this.filmInfo && this.filmInfo._id;
                const selectedOptionId =
                    this.selectedOption && this.selectedOption.id;
                const res = await videoAPI.unlockEpisodes({
                    showId: filmId,
                    configId: optionId || selectedOptionId,
                });
                if (res.status !== 200) {
                    throw new Error(res);
                } else {
                    console.log("unlockEpisodes res: ", res);
                    // 如果是單集解鎖，就同步當前自動解鎖選項到 localStorage 做紀錄
                    if (selectedOptionId === 1) {
                        const currentIsAutoUnlock = this.isAutoUnlock;
                        localStorage.setItem("autoUnlock", currentIsAutoUnlock);
                    }
                    this.$emit("episodeUnlocked");
                }
            } catch (err) {
                console.log("unlockEpisodes err: ", err);
                this.$message.error(this.$t("messages.errors.defaultError"), 3);
            }
            this.isLoading = false;
        },
        optionOnClick(option) {
            this.selectedOption = option;
        },
        unlockBtnOnClick() {
            this.unlockEpisodes();
        },
        payBtnOnClick() {
            // this.$router.push({ name: "store" });
            this.isEpisodePaymentPanelActive = true;
        },
        // 加值成功後關閉加值彈窗並解鎖所選方案
        handlePaymentOnSuccess() {
            this.isEpisodePaymentPanelActive = false;
            this.unlockEpisodes();
        },
    },
    created() {
        this.updateBalance();
    },
};
</script>
