var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isSettingsShow)?_c('div',{staticClass:"absolute left-0 top-0 flex h-screen w-screen items-center justify-center p-10",class:{ 'bg-[#000] bg-opacity-60': _vm.isConfigCompleted }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isConfigCompleted),expression:"!isConfigCompleted"}],staticClass:"absolute z-0 h-full w-full"},[_c('div',{staticClass:"absolute left-0 top-0 z-10 h-full w-full bg-[#000] bg-opacity-40"}),(_vm.introVideoSource)?_c('VideoPlayer',{attrs:{"refName":'introVideo',"isMute":true,"loop":true,"coverScreen":true,"autoPlay":true,"videoSrc":_vm.introVideoSource}}):_vm._e()],1),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isConfigCompleted),expression:"isConfigCompleted"}],staticClass:"absolute right-4 top-4 z-10 flex h-7 w-7 items-center justify-center text-center",on:{"click":_vm.closeBtnOnClick}},[_c('img',{staticClass:"h-full w-full",attrs:{"src":"assets/imgs/utils/icons/close_light.svg","alt":"close"}})]),_c('div',{staticClass:"no-scrollbar relative z-10 flex h-full w-full flex-col items-center gap-10 overflow-scroll"},[_c('div',{staticClass:"flex w-full flex-col gap-4"},[_c('span',{staticClass:"text-center text-2xl font-bold italic text-[#fff]"},[_vm._v(_vm._s(_vm.$t("components.settings.genderTitleText")))]),_c('div',{staticClass:"flex w-full items-center gap-4"},[_c('button',{staticClass:"flex h-12 w-1/2 items-center justify-center gap-1 rounded-full bg-[#fff] bg-opacity-45 px-5",class:{
                        '!bg-opacity-100': _vm.currentSelectedGender === 'male',
                    },attrs:{"disabled":_vm.isLoading},on:{"click":() => _vm.genderBtnOnClick('male')}},[_c('img',{staticClass:"h-6 w-6",attrs:{"src":"assets/imgs/views/index/gender_male_icon.svg","alt":""}}),_c('span',{staticClass:"text-xl font-bold italic text-[#000]"},[_vm._v(_vm._s(_vm.$t("components.settings.maleBtnText")))])]),_c('button',{staticClass:"flex h-12 w-1/2 items-center justify-center gap-1 rounded-full bg-[#fff] bg-opacity-45 px-5",class:{
                        '!bg-opacity-100':
                            _vm.currentSelectedGender === 'female',
                    },attrs:{"disabled":_vm.isLoading},on:{"click":() => _vm.genderBtnOnClick('female')}},[_c('img',{staticClass:"h-6 w-6",attrs:{"src":"assets/imgs/views/index/gender_female_icon.svg","alt":""}}),_c('span',{staticClass:"text-xl font-bold italic text-[#000]"},[_vm._v(_vm._s(_vm.$t("components.settings.femaleBtnText")))])])])]),_c('div',{staticClass:"flex w-full flex-col items-center gap-4"},[_c('span',{staticClass:"text-center text-2xl font-bold italic text-[#fff]"},[_vm._v(_vm._s(_vm.$t("components.settings.languageTitleText")))]),_c('div',{staticClass:"flex h-12 w-full items-center justify-center gap-3 rounded-full bg-[#fff] text-center"},[_c('div',{staticClass:"flex h-5 w-5 items-center justify-center rounded-full bg-[#FC2956] text-center"},[_c('svg-icon',{staticClass:"w-4 text-[#fff]",attrs:{"type":"mdi","path":_vm.mdiCheck}})],1),_c('span',{staticClass:"text-xl font-bold italic text-[#000]"},[_vm._v(_vm._s(_vm.$t(`language.${_vm.$i18n.locale}`)))])]),_c('button',{staticClass:"flex h-8 min-h-8 items-center justify-center gap-1 rounded-full bg-[#fff] bg-opacity-50 px-4",attrs:{"disabled":_vm.isLoading},on:{"click":_vm.languageSelectBtnOnClick}},[_c('span',{staticClass:"text-xs text-[#000]"},[_vm._v(_vm._s(_vm.$t("components.settings.chooseLanguageBtnText")))]),_c('svg-icon',{staticClass:"w-4 text-[#000]",attrs:{"type":"mdi","path":_vm.mdiChevronDown}})],1)]),_c('div',{staticClass:"flex w-full flex-col items-center gap-4"},[_c('span',{staticClass:"text-center text-2xl font-bold italic text-[#fff]"},[_vm._v(_vm._s(_vm.$t("components.settings.qualityTitleText")))]),_c('div',{staticClass:"flex w-full items-center justify-center gap-3"},_vm._l((_vm.qualityOptions),function(qualityOption){return _c('button',{key:qualityOption,staticClass:"flex h-12 w-[96px] items-center justify-center rounded-full bg-[#fff] bg-opacity-50 text-xl font-bold italic text-[#000]",class:{
                        '!bg-opacity-100':
                            _vm.currentSelectedQualityOption === qualityOption,
                    },on:{"click":() => _vm.qualitySelectBtnOnClick(qualityOption)}},[_vm._v(" "+_vm._s(`${qualityOption}P`)+" ")])}),0)]),(_vm.isConfigCompleted)?_c('div',{staticClass:"flex w-full items-center justify-center gap-4"},[_c('span',{staticClass:"text-2xl font-bold italic text-[#fff]"},[_vm._v(_vm._s(`${_vm.$t("components.settings.autoUnlockTitleText")}:`))]),_c('SwitchController',{attrs:{"controller":_vm.isAutoUnlock},on:{"update:controller":function($event){_vm.isAutoUnlock=$event}}})],1):_vm._e(),_c('button',{staticClass:"flex h-14 min-h-14 w-full items-center justify-center rounded-full bg-[#FC2956] text-center text-xl font-bold italic text-[#fff]",class:{ 'opacity-30': _vm.isLoading },attrs:{"disabled":_vm.isLoading},on:{"click":_vm.confirmBtnOnClick}},[_vm._v(" "+_vm._s(_vm.$t("utils.confirm"))+" ")])]),_c('LanguageSelector',{attrs:{"isLanguageSelectorActive":_vm.isLanguageSelectorActive},on:{"close":function($event){_vm.isLanguageSelectorActive = false}}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }