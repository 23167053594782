<template>
    <transition name="fade">
        <div
            v-show="isFilmSelectPanelActive"
            class="fixed left-0 top-0 z-10 h-screen w-screen"
        >
            <!-- overlay -->
            <div
                @click="close"
                class="absolute left-0 top-0 z-[-1] h-full w-full bg-[#000] bg-opacity-65"
            ></div>
            <!-- panel content -->
            <div
                class="absolute bottom-0 left-0 flex h-[calc(100%-40px)] w-full flex-col gap-5 rounded-t-xl bg-[#000] bg-opacity-65 p-6"
                style="backdrop-filter: blur(10px)"
            >
                <!-- close button -->
                <button
                    @click="close"
                    class="absolute right-3 top-3 h-7 w-7 opacity-15"
                >
                    <img
                        src="assets/imgs/utils/icons/close_light.svg"
                        alt=""
                        class="h-full w-full"
                    />
                </button>
                <!-- tags wrapper container -->
                <div class="no-scrollbar w-[calc(100%-32px)] overflow-scroll">
                    <!-- tags wrapper -->
                    <div class="flex w-full items-end gap-4">
                        <!-- tag button -->
                        <button
                            v-for="(tag, index) in tags"
                            :key="index"
                            @click="currentSelectedTag = tag"
                            class="relative flex items-center justify-center overflow-visible text-center"
                        >
                            <span
                                class="relative z-10 text-base font-bold italic text-[#fff]"
                                :class="{
                                    '!text-2xl': currentSelectedTag === tag,
                                }"
                                >{{
                                    $t(
                                        `views.index.filmSelectPanel.tags.${tag}`,
                                    )
                                }}</span
                            >
                            <div
                                v-show="currentSelectedTag === tag"
                                class="absolute bottom-0 z-0 h-2 w-full bg-[#FC2956]"
                            ></div>
                        </button>
                    </div>
                </div>
                <!-- films wrapper container -->
                <div class="no-scrollbar w-full flex-grow overflow-scroll">
                    <!-- skeleton films wrapper -->
                    <div
                        v-if="isLoading"
                        class="flex w-full flex-wrap gap-x-[15px] gap-y-4"
                    >
                        <!-- skeleton film -->
                        <div
                            v-for="i in 12"
                            :key="i"
                            class="h-[140px] w-[calc(33.333%-10px)] animate-pulse rounded-xl bg-[#fff] bg-opacity-25"
                        ></div>
                    </div>
                    <!-- films wrapper -->
                    <div
                        v-else
                        class="flex w-full flex-wrap gap-x-[15px] gap-y-4"
                    >
                        <!-- film -->
                        <button
                            v-for="film in currentSelectedTagFilms"
                            :key="film._id"
                            :disabled="film.comingSoon"
                            @click="() => filmOnClick(film)"
                            class="relative h-[140px] min-h-[140px] w-[calc(33.333%-10px)] overflow-hidden rounded-xl bg-[#fff] bg-opacity-25"
                        >
                            <img
                                :src="film.pic"
                                class="absolute left-0 top-0 z-0 h-full w-full object-cover"
                                alt="film_pic"
                            />
                            <!-- 圖片用黑色遮罩 -->
                            <div
                                v-show="film.comingSoon"
                                class="absolute left-0 top-0 z-0 h-full w-full bg-[#000] bg-opacity-80"
                            ></div>
                            <!-- coming soon content -->
                            <div
                                v-if="film.comingSoon"
                                class="relative flex h-full w-full flex-col items-center justify-center gap-[10px] px-4"
                            >
                                <span
                                    class="text-center text-sm font-bold italic leading-4 text-[#fff]"
                                    >{{
                                        $t(
                                            "views.index.filmSelectPanel.comingSoon",
                                        )
                                    }}</span
                                >
                                <span
                                    class="whitespace-nowrap text-xs text-[#fff]"
                                    >{{ film.startTime }}</span
                                >
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <EpisodeSelectPanel
                @close="isEpisodeSelectPanelActive = false"
                @episodeSelected="episodeSelected"
                @unlockEpisode="unlockEpisode"
                :isEpisodeSelectPanelActive="isEpisodeSelectPanelActive"
                :filmInfo="currentSelectedFilm"
                :shouldSelectEpisodePanelUpdateUnlockStatus.sync="
                    shouldSelectEpisodePanelUpdateUnlockStatus
                "
            />
            <EpisodeUnlockPanel
                :isEpisodeUnlockPanelActive="isEpisodeUnlockPanelActive"
                :filmInfo="currentSelectedFilm"
                @close="isEpisodeUnlockPanelActive = false"
                @episodeUnlocked="onEpisodeUnlocked"
            />
        </div>
    </transition>
</template>

<script>
import videoAPI from "@/apis/video";
import EpisodeSelectPanel from "./EpisodeSelectPanel.vue";
import EpisodeUnlockPanel from "./EpisodeUnlockPanel.vue";

export default {
    name: "FilmSelectPanel",
    components: {
        EpisodeSelectPanel,
        EpisodeUnlockPanel,
    },
    props: {
        isFilmSelectPanelActive: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isLoading: false,
        isEpisodeSelectPanelActive: false,
        isEpisodeUnlockPanelActive: false,
        shouldSelectEpisodePanelUpdateUnlockStatus: false,
        tags: ["popular", "magical"],
        films: null,
        currentSelectedTag: "popular",
        currentSelectedFilm: null,
        currentUnlockingEpisodeConfig: null,
    }),
    computed: {
        activeFilms() {
            return (
                this.films &&
                this.films.length &&
                this.films.filter((film) => film.active)
            );
        },
        currentSelectedTagFilms() {
            return (
                (this.activeFilms &&
                    this.activeFilms.length &&
                    this.activeFilms.filter((film) =>
                        film.type.includes(this.currentSelectedTag),
                    )) ||
                []
            );
        },
    },
    watch: {
        isFilmSelectPanelActive(newVal) {
            // 開啟時獲取影集列表
            if (newVal) {
                this.getFilms();
            }
        },
    },
    methods: {
        async getFilms() {
            this.isLoading = true;
            try {
                const res = await videoAPI.getFilms();
                if (res.status !== 200) {
                    throw new Error(res);
                } else {
                    console.log("getFilms res: ", res);
                    const films = res.data;
                    this.films = films;
                }
            } catch (err) {
                console.log("getFilms err: ", err);
                this.$message.error(this.$t("messages.errors.defaultError"), 3);
            }
            this.isLoading = false;
        },
        filmOnClick(film) {
            const filmId = film._id;
            this.currentSelectedFilm = film;
            this.isEpisodeSelectPanelActive = true;
        },
        episodeSelected(episodeNumber) {
            const selectedFilmId =
                this.currentSelectedFilm && this.currentSelectedFilm._id;
            this.$router.push({
                query: { film: selectedFilmId, episode: episodeNumber },
            });
            this.close();
        },
        // 用戶打算解鎖劇集
        unlockEpisode(unlockEpisodeInfo) {
            this.currentUnlockingEpisodeConfig = unlockEpisodeInfo;
            this.isEpisodeUnlockPanelActive = true;
        },
        // 當有劇集被解鎖時
        onEpisodeUnlocked() {
            this.shouldSelectEpisodePanelUpdateUnlockStatus = true;
            // 關閉解鎖彈窗
            this.isEpisodeUnlockPanelActive = false;
        },
        close() {
            this.$emit("close");
        },
    },
};
</script>
