<template>
    <div ref="lottieContainer" :class="`w-[${width}] h-[${height}]`"></div>
</template>

<script>
import lottie from "lottie-web";

export default {
    name: "LottieAnimation",
    props: {
        width: {
            type: String,
            default: "100px",
        },
        height: {
            type: String,
            default: "100px",
        },
        animationData: {
            type: Object,
            required: true,
        },
        loop: {
            type: Boolean,
            default: true,
        },
        autoplay: {
            type: Boolean,
            default: true,
        },
    },
    mounted() {
        this.initLottie();
    },
    methods: {
        initLottie() {
            lottie.loadAnimation({
                container: this.$refs.lottieContainer, // 使用 ref
                renderer: "svg",
                loop: this.loop,
                autoplay: this.autoplay,
                animationData: this.animationData, // 傳入 Lottie JSON 數據
            });
        },
    },
};
</script>
