import Vue from "vue";
import VueRouter from "vue-router";
import Index from "@/views/index/Index";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "index",
        component: Index,
        meta: {
            isRoot: true,
        },
    },
    {
        path: "/my",
        name: "my",
        component: () => import("@/views/my/My.vue"),
    },
    {
        path: "/store",
        name: "store",
        component: () => import("@/views/store/Store.vue"),
    },
    {
        path: "*",
        redirect: { name: "index" },
    },
];

const router = new VueRouter({
    routes,
});

export default router;
