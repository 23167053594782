function preloadResources(resources, callback) {
    let loadedResources = 0;
    const totalResources = resources.length;
    if (!totalResources) {
        callback();
        return;
    }

    resources.forEach((resource) => {
        const img = new Image();
        img.src = resource;
        img.onload = () => {
            loadedResources += 1;
            if (loadedResources === totalResources) {
                callback();
            }
        };
        img.onerror = () => {
            console.error(`Failed to load resource: ${resource}`);
            loadedResources += 1;
            if (loadedResources === totalResources) {
                callback();
            }
        };
    });
}

const resourcesToPreload = [
    // "assets/imgs/views/wallet/wallet-bg.svg",
    // "assets/imgs/views/wallet/wallet-gift-cover.png",
];

export function preload(callback) {
    preloadResources(resourcesToPreload, callback);
}
