<template>
    <transition name="fade">
        <div
            v-show="isEpisodeSelectPanelActive"
            class="fixed left-0 top-0 z-10 h-screen w-screen"
        >
            <!-- overlay -->
            <div
                @click="close"
                class="absolute left-0 top-0 z-[-1] h-full w-full bg-[#000] bg-opacity-65"
            ></div>
            <!-- panel content -->
            <div
                class="absolute bottom-0 left-0 flex h-[320px] w-full flex-col gap-5 rounded-t-xl bg-[#000] bg-opacity-65 p-6"
                style="backdrop-filter: blur(10px)"
            >
                <!-- close button -->
                <button
                    @click="close"
                    class="absolute right-3 top-3 h-7 w-7 opacity-15"
                >
                    <img
                        src="assets/imgs/utils/icons/close_light.svg"
                        alt=""
                        class="h-full w-full"
                    />
                </button>
                <!-- film title row -->
                <div class="flex w-full flex-col gap-1">
                    <!-- film name -->
                    <span class="text-2xl font-bold italic text-[#fff]">{{
                        filmName
                    }}</span>
                    <!-- episode number -->
                    <span class="text-xs text-[#fff]">{{
                        $t("views.index.episodeSelectPanel.episodeAmountText", {
                            episodeAmount: filmTotalEpisodeAmount,
                        })
                    }}</span>
                </div>
                <!-- film wrapper container -->
                <div class="no-scrollbar w-full overflow-scroll">
                    <!-- skeleton episode buttons -->
                    <div v-if="isLoading" class="flex w-full flex-wrap gap-2">
                        <div
                            v-for="i in 12"
                            :key="i"
                            class="relative flex h-10 w-[calc(25%-6px)] animate-pulse items-center justify-center overflow-hidden rounded-xl bg-[#fff] bg-opacity-25"
                        ></div>
                    </div>
                    <div v-else class="flex w-full flex-wrap gap-2">
                        <!-- episode button -->
                        <button
                            v-for="i in filmTotalEpisodeAmount"
                            :key="i"
                            @click="() => episodeBtnOnClick(i)"
                            :disabled="
                                isEpisodeCurrentPlaying(i) ||
                                isUnlockStatusLoading
                            "
                            class="relative flex h-10 w-[calc(25%-6px)] items-center justify-center overflow-hidden rounded-xl bg-[#fff] bg-opacity-25"
                            :class="{
                                '!bg-opacity-100': isEpisodeCurrentPlaying(i),
                                '!opacity-30': isUnlockStatusLoading,
                            }"
                        >
                            <!-- locked icon -->
                            <div
                                v-show="i > filmUnlockedEpisodeAmount"
                                class="absolute right-0 top-0 z-0 flex h-5 w-5 items-center justify-center rounded-bl-xl bg-[#FC2956] text-center"
                            >
                                <svg-icon
                                    type="mdi"
                                    :path="mdiLock"
                                    class="h-3 w-3 text-[#fff]"
                                ></svg-icon>
                            </div>
                            <!-- episode number -->
                            <span
                                class="relative z-10 text-center text-sm font-bold text-[#fff]"
                                :class="{
                                    '!text-[#FC2956]':
                                        isEpisodeCurrentPlaying(i),
                                }"
                                >{{ i }}</span
                            >
                            <svg-icon
                                v-show="isEpisodeCurrentPlaying(i)"
                                type="mdi"
                                :path="mdiPlay"
                                class="h-4 w-4 text-[#FC2956]"
                            ></svg-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiLock, mdiPlay } from "@mdi/js";
import videoAPI from "@/apis/video";

export default {
    name: "EpisodeSelectPanel",
    components: {
        SvgIcon,
    },
    props: {
        isEpisodeSelectPanelActive: {
            type: Boolean,
            default: false,
        },
        isUnlockStatusLoading: {
            type: Boolean,
            default: false,
        },
        filmInfo: {
            type: Object,
            default: null,
        },
        shouldSelectEpisodePanelUpdateUnlockStatus: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        mdiLock,
        mdiPlay,
        isLoading: false,
        userUnlockEpisodeStatus: null,
    }),
    computed: {
        filmName() {
            const currentLocale = this.$i18n.locale;
            const filmNameLocale =
                this.filmInfo &&
                this.filmInfo.name &&
                this.filmInfo.name[currentLocale];
            const filmNameEn =
                this.filmInfo && this.filmInfo.name && this.filmInfo.name["en"];
            return filmNameLocale || filmNameEn || "";
        },
        filmTotalEpisodeAmount() {
            return (
                this.filmInfo &&
                this.filmInfo.episodes &&
                this.filmInfo.episodes.totalNumber
            );
        },
        filmUnlockedEpisodeAmount() {
            const filmDefaultUnlockNumber =
                this.filmInfo &&
                this.filmInfo.episodes &&
                this.filmInfo.episodes.unlockNumber;
            const userUnlockNumber =
                this.userUnlockEpisodeStatus &&
                this.userUnlockEpisodeStatus.unlockNumber;
            return userUnlockNumber || filmDefaultUnlockNumber;
        },
    },
    watch: {
        // 開啟時獲取用戶解鎖進度
        isEpisodeSelectPanelActive(newVal) {
            if (newVal) {
                this.getFilmUnlockStatus();
            }
        },
        // 更新解鎖進度的 hook 被觸發時進行更新
        shouldSelectEpisodePanelUpdateUnlockStatus(newVal) {
            if (newVal) {
                this.getFilmUnlockStatus();
                // 重置 hook
                this.$emit(
                    "update:shouldSelectEpisodePanelUpdateUnlockStatus",
                    false,
                );
            }
        },
    },
    methods: {
        // 獲取當前劇集用戶解鎖狀態
        async getFilmUnlockStatus() {
            this.isLoading = true;
            try {
                const filmId = this.filmInfo && this.filmInfo._id;
                const res = await videoAPI.getFilmUnlockStatus(filmId);
                if (res.status !== 200) {
                    throw new Error(res);
                } else {
                    console.log("getFilmUnlockStatus res: ", res);
                    const unlockStatus = res.data;
                    this.userUnlockEpisodeStatus = unlockStatus;
                }
            } catch (err) {
                console.log("getFilmUnlockStatus err: ", err);
                this.$message.error(this.$t("messages.errors.defaultError"), 3);
            }
            this.isLoading = false;
        },
        close() {
            this.$emit("close");
        },
        isEpisodeCurrentPlaying(episodeNumber) {
            const currentPlayingFilmId = this.$route.query.film;
            const currentPlayingEpisodeNumber = this.$route.query.episode;
            const isSelectedFilmCurrentFilm =
                this.filmInfo &&
                `${this.filmInfo._id}` === `${currentPlayingFilmId}`;
            const isEpisodeNumberCurrentEpisode =
                `${episodeNumber}` === `${currentPlayingEpisodeNumber}`;
            return isSelectedFilmCurrentFilm && isEpisodeNumberCurrentEpisode;
        },
        episodeBtnOnClick(episodeNumber) {
            const isEpisodeLocked =
                episodeNumber > this.filmUnlockedEpisodeAmount;
            const isEpisodeCantUnlock =
                episodeNumber > this.filmUnlockedEpisodeAmount + 1;
            // 不能跳集解鎖
            if (isEpisodeCantUnlock) {
                this.$message.warning(
                    this.$t(
                        "views.index.episodeSelectPanel.episodeCantUnlockMessage",
                    ),
                    3,
                );
                return;
            }
            // 鎖住的話彈出解鎖畫面
            if (isEpisodeLocked) {
                const unlockEpisodeInfo = {
                    filmInfo: this.filmInfo,
                    episodeNumber: episodeNumber,
                };
                this.$emit("unlockEpisode", unlockEpisodeInfo);
                // this.close();
                return;
            }
            // 可以直接看的就回船事件
            this.$emit("episodeSelected", episodeNumber);
            this.close();
        },
    },
};
</script>
