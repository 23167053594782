<template>
    <div
        @click="everyOnclick"
        id="app"
        class="top-0 flex h-screen max-h-screen w-screen flex-col justify-between bg-[#000]"
    >
        <div class="relative flex flex-grow overflow-auto p-6">
            <router-view :key="$route.path"></router-view>
        </div>
        <Settings />
        <!-- <WalletConnector /> -->
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Settings from "@/components/settings/Settings.vue";
// import WalletConnector from "@/components/walletConnector/WalletConnector.vue";

export default {
    name: "App",
    components: {
        Settings,
        // WalletConnector,
    },
    data: () => ({
        isCurrentRouteRoot: true,
    }),
    computed: {
        ...mapGetters(["userInfo"]),
    },
    watch: {
        $route(to, from) {
            console.log("to: ", to);
            console.log("from: ", from);
            if (to.meta.isRoot) {
                this.isCurrentRouteRoot = true;
                window.Telegram.WebApp.BackButton.hide();
            } else {
                this.isCurrentRouteRoot = false;
                window.Telegram.WebApp.BackButton.show();
            }
        },
    },
    methods: {
        async login() {
            try {
                const err = await this.$store.dispatch(
                    "updateInitDataUserInfo",
                );
                if (err) {
                    throw new Error(JSON.stringify(err));
                } else {
                    // this.getTokenList();
                    // this.$store.dispatch("updateGifts");
                }
            } catch (err) {
                window.Telegram.WebApp.showAlert(err);
            }
        },
        handleTelegramBackBtnClicked() {
            window.Telegram.WebApp.onEvent("backButtonClicked", () => {
                // 特例處理 (從資產頁返回上一頁固定回到首頁)
                if (this.$route.name === "assets") {
                    this.$router.replace("/wallet");
                    return;
                }
                // 有上一页则返回
                if (window.history.length > 1) {
                    // this.$router.go(-1);
                    window.history.back();
                } else {
                    // 没有上一页则返回到首页
                    this.$router.replace("/wallet");
                }
                // history.back();
                //this.$router.push({ name: "index" });
            });
        },
        tgInit() {
            if (window.Telegram && window.Telegram.WebApp) {
                const webApp = window.Telegram.WebApp;
                console.log(window.Telegram.WebApp);
                webApp.expand();
                webApp.enableClosingConfirmation();
                webApp.disableVerticalSwipes();
                console.log(webApp);
                if (webApp.initDataUnsafe.start_param) {
                    this.getMiniappParameter(webApp.initDataUnsafe.start_param);
                    this.$store.commit("setLaunchMethod", "MINIAPP"); // 调用 mutation
                }
                // set theme colors
                console.log("theme color: ", webApp.themeParams);
            } else {
                console.error("Telegram or Telegram.WebApp is not available.");
            }
        },

        // get miniapp parameter
        getMiniappParameter(start_param) {
            console.log("start_param", start_param);

            // 使用 split 方法按 "_" 切分字符串
            const parts = start_param.split("-");
            // 第一个元素作为命令
            const command = parts[0];
            // 剩余的所有元素作为参数数组
            const params = parts.slice(1);

            console.log("command", command);
            console.log("params", params);

            const commandMap = {
                // open specific show & episode
                o: function (_this, _showId, _episode) {
                    if (_showId && _episode) {
                        console.log(`show episode: ${_showId}, ${_episode}`);
                        this.$router.push({
                            name: "index",
                            query: {
                                show: _showId,
                                episode: _episode,
                            },
                        });
                    }
                },
            };

            if (command && params[0] && commandMap[command]) {
                commandMap[command](this, params[0], params[1], params[2]);
                return;
            }
        },
        // 確認用戶是否已完成設置，沒有的話就彈出設置頁面
        checkIsUserConfigCompleted() {
            const localIsUserConfigCompleted = localStorage.getItem(
                "isUserConfigCompleted",
            );
            if (localIsUserConfigCompleted === "true") return;
            this.$store.commit("setIsSettingsShow", true);
        },
        //! 留著 用來觸發 blur，解決 input click outside unfocus 失效問題
        everyOnclick(event) {},
    },
    created() {
        console.log("06071038");
        console.log("current env", process.env.NODE_ENV);
        console.log("current VUE_APP_BOT_URL", process.env.VUE_APP_BOT_URL);
        this.tgInit();
        this.login();
        this.handleTelegramBackBtnClicked();
        this.checkIsUserConfigCompleted();
    },

    mounted() {
        window.Telegram.WebApp.ready();
    },
};
</script>
