<template>
    <div
        v-if="isSettingsShow"
        class="absolute left-0 top-0 flex h-screen w-screen items-center justify-center p-10"
        :class="{ 'bg-[#000] bg-opacity-60': isConfigCompleted }"
    >
        <!-- 首次進入時需要背景播放宣傳片 (靜音) -->
        <div v-show="!isConfigCompleted" class="absolute z-0 h-full w-full">
            <!-- 遮罩 -->
            <div
                class="absolute left-0 top-0 z-10 h-full w-full bg-[#000] bg-opacity-40"
            ></div>
            <VideoPlayer
                v-if="introVideoSource"
                :refName="'introVideo'"
                :isMute="true"
                :loop="true"
                :coverScreen="true"
                :autoPlay="true"
                :videoSrc="introVideoSource"
            />
        </div>
        <!-- close button -->
        <button
            v-show="isConfigCompleted"
            @click="closeBtnOnClick"
            class="absolute right-4 top-4 z-10 flex h-7 w-7 items-center justify-center text-center"
        >
            <img
                src="assets/imgs/utils/icons/close_light.svg"
                class="h-full w-full"
                alt="close"
            />
        </button>
        <!-- content -->
        <div
            class="no-scrollbar relative z-10 flex h-full w-full flex-col items-center gap-10 overflow-scroll"
        >
            <!-- gender select wrapper -->
            <div class="flex w-full flex-col gap-4">
                <!-- title text -->
                <span
                    class="text-center text-2xl font-bold italic text-[#fff]"
                    >{{ $t("components.settings.genderTitleText") }}</span
                >
                <!-- buttons wrapper -->
                <div class="flex w-full items-center gap-4">
                    <!-- male button -->
                    <button
                        @click="() => genderBtnOnClick('male')"
                        :disabled="isLoading"
                        class="flex h-12 w-1/2 items-center justify-center gap-1 rounded-full bg-[#fff] bg-opacity-45 px-5"
                        :class="{
                            '!bg-opacity-100': currentSelectedGender === 'male',
                        }"
                    >
                        <img
                            src="assets/imgs/views/index/gender_male_icon.svg"
                            alt=""
                            class="h-6 w-6"
                        />
                        <span class="text-xl font-bold italic text-[#000]">{{
                            $t("components.settings.maleBtnText")
                        }}</span>
                    </button>
                    <!-- female button -->
                    <button
                        @click="() => genderBtnOnClick('female')"
                        :disabled="isLoading"
                        class="flex h-12 w-1/2 items-center justify-center gap-1 rounded-full bg-[#fff] bg-opacity-45 px-5"
                        :class="{
                            '!bg-opacity-100':
                                currentSelectedGender === 'female',
                        }"
                    >
                        <img
                            src="assets/imgs/views/index/gender_female_icon.svg"
                            alt=""
                            class="h-6 w-6"
                        />
                        <span class="text-xl font-bold italic text-[#000]">{{
                            $t("components.settings.femaleBtnText")
                        }}</span>
                    </button>
                </div>
            </div>
            <!-- language selection wrapper -->
            <div class="flex w-full flex-col items-center gap-4">
                <!-- title text -->
                <span
                    class="text-center text-2xl font-bold italic text-[#fff]"
                    >{{ $t("components.settings.languageTitleText") }}</span
                >
                <!-- current selected language -->
                <div
                    class="flex h-12 w-full items-center justify-center gap-3 rounded-full bg-[#fff] text-center"
                >
                    <div
                        class="flex h-5 w-5 items-center justify-center rounded-full bg-[#FC2956] text-center"
                    >
                        <svg-icon
                            type="mdi"
                            :path="mdiCheck"
                            class="w-4 text-[#fff]"
                        ></svg-icon>
                    </div>
                    <span class="text-xl font-bold italic text-[#000]">{{
                        $t(`language.${$i18n.locale}`)
                    }}</span>
                </div>
                <!-- choose language button -->
                <button
                    @click="languageSelectBtnOnClick"
                    :disabled="isLoading"
                    class="flex h-8 min-h-8 items-center justify-center gap-1 rounded-full bg-[#fff] bg-opacity-50 px-4"
                >
                    <span class="text-xs text-[#000]">{{
                        $t("components.settings.chooseLanguageBtnText")
                    }}</span>
                    <svg-icon
                        type="mdi"
                        :path="mdiChevronDown"
                        class="w-4 text-[#000]"
                    ></svg-icon>
                </button>
            </div>
            <!-- quality selection wrapper -->
            <div class="flex w-full flex-col items-center gap-4">
                <!-- title text -->
                <span
                    class="text-center text-2xl font-bold italic text-[#fff]"
                    >{{ $t("components.settings.qualityTitleText") }}</span
                >
                <!-- quality buttons wrapper -->
                <div class="flex w-full items-center justify-center gap-3">
                    <!-- quality button -->
                    <button
                        v-for="qualityOption in qualityOptions"
                        :key="qualityOption"
                        @click="() => qualitySelectBtnOnClick(qualityOption)"
                        class="flex h-12 w-[96px] items-center justify-center rounded-full bg-[#fff] bg-opacity-50 text-xl font-bold italic text-[#000]"
                        :class="{
                            '!bg-opacity-100':
                                currentSelectedQualityOption === qualityOption,
                        }"
                    >
                        {{ `${qualityOption}P` }}
                    </button>
                </div>
            </div>
            <!-- auto unlock switch -->
            <div
                v-if="isConfigCompleted"
                class="flex w-full items-center justify-center gap-4"
            >
                <span class="text-2xl font-bold italic text-[#fff]">{{
                    `${$t("components.settings.autoUnlockTitleText")}:`
                }}</span>
                <SwitchController :controller.sync="isAutoUnlock" />
            </div>
            <!-- confirm button -->
            <button
                @click="confirmBtnOnClick"
                :disabled="isLoading"
                class="flex h-14 min-h-14 w-full items-center justify-center rounded-full bg-[#FC2956] text-center text-xl font-bold italic text-[#fff]"
                :class="{ 'opacity-30': isLoading }"
            >
                {{ $t("utils.confirm") }}
            </button>
        </div>
        <LanguageSelector
            @close="isLanguageSelectorActive = false"
            :isLanguageSelectorActive="isLanguageSelectorActive"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import userAPI from "@/apis/user";
import VideoPlayer from "@/components/VideoPlayer.vue";
import LanguageSelector from "./components/LanguageSelector.vue";
import SwitchController from "@/components/SwitchController.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiCheck, mdiChevronDown } from "@mdi/js";

export default {
    name: "Settings",
    components: {
        VideoPlayer,
        LanguageSelector,
        SwitchController,
        SvgIcon,
    },
    data: () => ({
        mdiCheck,
        mdiChevronDown,
        isLoading: false,
        introVideoSource: null,
        isLanguageSelectorActive: false,
        isAutoUnlock: true,
        isConfigCompleted: false,
        qualityOptions: ["640", "1138", "1920"],
        currentSelectedGender: null,
        currentSelectedQualityOption: null,
    }),
    computed: {
        ...mapGetters(["isSettingsShow"]),
    },
    watch: {
        isSettingsShow(newVal) {
            if (newVal) {
                // 開啟時清除狀態並重新獲取狀態
                this.clearCurrentSelection();
                this.getLocalConfigCompletion();
                this.getLocalGender();
                this.getLocalQuality();
                this.getLocalAutoUnlock();
            }
        },
    },
    methods: {
        getIntroVideoSource() {
            let url = "/v/intro/intro.m3u8";
            if (window.location.hostname === "localhost") {
                url = `https://devvideomini.tongifts.app${url}`;
            }
            this.introVideoSource = url;
        },
        getLocalConfigCompletion() {
            const localIsUserConfigCompleted = localStorage.getItem(
                "isUserConfigCompleted",
            );
            this.isConfigCompleted = localIsUserConfigCompleted === "true";
            if (!this.isConfigCompleted) {
                this.getIntroVideoSource();
            }
        },
        getLocalGender() {
            const localGender = localStorage.getItem("gender");
            this.currentSelectedGender = localGender || "male";
        },
        getLocalQuality() {
            console.log("getLocalQuality");
            const localQuality = localStorage.getItem("quality");
            if (localQuality) {
                this.$store.commit("setVideoQuality", localQuality);
            }
            this.currentSelectedQualityOption =
                localQuality || this.qualityOptions[1];
        },
        getLocalAutoUnlock() {
            const localAutoUnlock = localStorage.getItem("autoUnlock");
            if (localAutoUnlock && localAutoUnlock === "false") {
                this.isAutoUnlock = false;
                return;
            } else {
                this.isAutoUnlock = true;
            }
        },
        closeSettingsPage() {
            this.$store.commit("setIsSettingsShow", false);
        },
        closeBtnOnClick() {
            this.closeSettingsPage();
        },
        genderBtnOnClick(genderId) {
            this.currentSelectedGender = genderId;
        },
        clearCurrentSelection() {
            this.currentSelectedGender = null;
            this.currentSelectedQualityOption = null;
        },
        languageSelectBtnOnClick() {
            this.isLanguageSelectorActive = true;
        },
        qualitySelectBtnOnClick(qualityOption) {
            this.currentSelectedQualityOption = qualityOption;
        },
        async confirmBtnOnClick() {
            this.isLoading = true;
            try {
                const selectedGender = this.currentSelectedGender;
                const res = await userAPI.setGender(selectedGender);
                if (res.status !== 200) {
                    throw new Error(res);
                } else {
                    console.log("set gender res: ", res);
                    localStorage.setItem("gender", this.currentSelectedGender);
                    localStorage.setItem("isUserConfigCompleted", "true");
                    localStorage.setItem(
                        "quality",
                        this.currentSelectedQualityOption,
                    );
                    localStorage.setItem("autoUnlock", this.isAutoUnlock);
                    this.$store.commit(
                        "setVideoQuality",
                        this.currentSelectedQualityOption,
                    );
                    this.closeSettingsPage();
                }
            } catch (err) {
                console.log("set gender error: ", err);
                this.$message.error(this.$t("messages.errors.defaultError"), 3);
            }
            this.isLoading = false;
        },
    },
    created() {
        this.getLocalConfigCompletion();
        this.getLocalGender();
        this.getLocalQuality();
        this.getLocalAutoUnlock();
    },
};
</script>
