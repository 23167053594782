<template>
    <transition name="fade">
        <div
            v-show="isSharePanelActive"
            class="fixed left-0 top-0 z-10 h-screen w-screen"
        >
            <!-- overlay -->
            <div
                @click="close"
                class="absolute left-0 top-0 z-[-1] h-full w-full bg-[#000] bg-opacity-65"
            ></div>
            <!-- panel content -->
            <div
                class="absolute bottom-0 left-0 flex w-full flex-col gap-5 rounded-t-xl bg-[#000] bg-opacity-65 p-6"
                style="backdrop-filter: blur(10px)"
            >
                <!-- close button -->
                <button
                    @click="close"
                    class="absolute right-3 top-3 h-7 w-7 opacity-15"
                >
                    <img
                        src="assets/imgs/utils/icons/close_light.svg"
                        alt=""
                        class="h-full w-full"
                    />
                </button>
                <!-- title text -->
                <span class="text-2xl font-bold italic text-[#fff]">{{
                    $t("views.index.sharePanel.titleText")
                }}</span>
                <!-- share options wrapper -->
                <div class="flex w-full justify-center gap-2">
                    <!-- 分享鏈結 -->
                    <button
                        @click="shareLinkBtnOnClick"
                        class="flex h-20 w-20 flex-col items-center justify-center gap-2"
                    >
                        <div
                            class="flex h-12 w-12 items-center justify-center rounded-full bg-[#fff] bg-opacity-15"
                        >
                            <svg-icon
                                type="mdi"
                                :path="mdiShareVariant"
                                class="h-6 w-6 text-[#FC2956]"
                            ></svg-icon>
                        </div>
                        <span
                            class="w-full whitespace-nowrap text-center text-xs text-[#fff]"
                            >{{
                                $t("views.index.sharePanel.shareLinkBtnText")
                            }}</span
                        >
                    </button>
                    <!-- 複製鏈結 -->
                    <button
                        @click="copyLinkBtnOnClick"
                        class="flex h-20 w-20 flex-col items-center justify-center gap-2"
                    >
                        <div
                            class="flex h-12 w-12 items-center justify-center rounded-full bg-[#fff] bg-opacity-15"
                        >
                            <svg-icon
                                type="mdi"
                                :path="mdiLink"
                                class="h-6 w-6 text-[#FC2956]"
                            ></svg-icon>
                        </div>
                        <span
                            class="w-full whitespace-nowrap text-center text-xs text-[#fff]"
                            >{{
                                $t("views.index.sharePanel.copyLinkBtnText")
                            }}</span
                        >
                    </button>
                    <!-- 更多分享方法 -->
                    <button
                        v-show="isNavigatorShareAvailable"
                        @click="moreBtnOnClick"
                        class="flex h-20 w-20 flex-col items-center justify-center gap-2"
                    >
                        <div
                            class="flex h-12 w-12 items-center justify-center rounded-full bg-[#fff] bg-opacity-15"
                        >
                            <svg-icon
                                type="mdi"
                                :path="mdiDotsHorizontal"
                                class="h-6 w-6 text-[#FC2956]"
                            ></svg-icon>
                        </div>
                        <span
                            class="w-full whitespace-nowrap text-center text-xs text-[#fff]"
                            >{{
                                $t("views.index.sharePanel.moreBtnText")
                            }}</span
                        >
                    </button>
                </div>
                <!-- share to chat button -->
                <button
                    @click="shareToChatBtnOnClick"
                    class="flex h-14 w-full items-center justify-center rounded-full bg-[#FC2956] text-center text-xl font-bold italic text-[#fff]"
                >
                    {{ $t("views.index.sharePanel.shareToChatBtnText") }}
                </button>
            </div>
        </div>
    </transition>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiShareVariant, mdiLink, mdiDotsHorizontal } from "@mdi/js";

export default {
    name: "SharePanel",
    components: {
        SvgIcon,
    },
    props: {
        isSharePanelActive: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        mdiShareVariant,
        mdiLink,
        mdiDotsHorizontal,
        isNavigatorShareAvailable: false,
    }),
    methods: {
        close() {
            this.$emit("close");
        },
        copyText(text) {
            console.log("copy text:", text);
            if (navigator.clipboard && window.isSecureContext) {
                // navigator.clipboard API method
                navigator.clipboard
                    .writeText(text)
                    .then(() => {
                        this.$message.success(
                            this.$t("messages.success.textCopySucceed"),
                            3,
                        );
                    })
                    .catch((err) => {
                        console.error("Could not copy text: ", err);
                        // 嘗試舊版
                        this.oldVersionCopyText(text);
                    });
            } else {
                // Fallback method for older browsers or non-secure context
                this.oldVersionCopyText(text);
            }
        },
        // 舊版剪貼簿功能
        oldVersionCopyText(text) {
            let textArea = document.createElement("textarea");
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                let successful = document.execCommand("copy");
                let msg = successful ? "successful" : "unsuccessful";
                console.log("Fallback: Copying text command was " + msg);
                this.$message.success(
                    this.$t("messages.success.textCopySucceed"),
                    3,
                );
            } catch (err) {
                console.error("Fallback: Oops, unable to copy", err);
                this.$message.error(this.$t("messages.errors.defaultError"), 3);
            }

            document.body.removeChild(textArea);
        },
        // 分享鏈結
        shareLinkBtnOnClick() {
            const botUrl = process.env.VUE_APP_BOT_URL;
            const miniAppName = process.env.VUE_APP_MINIAPP;
            const miniAppUrl = `${botUrl}/${miniAppName}`;
            let shareText = `\nI'm already immersed in some captivating short dramas on ShorTV, with opportunities to watch for free. Join me to experience more exciting stories! Tune in now!\n\n👆Click Link above to open 🌈⬆️`;
            shareText = encodeURIComponent(shareText);
            window.open(
                `https://t.me/share/url?url=${miniAppUrl}&text=${shareText}`,
            );
        },
        // 複製連結
        copyLinkBtnOnClick() {
            const botUrl = process.env.VUE_APP_BOT_URL;
            const miniAppName = process.env.VUE_APP_MINIAPP;
            const miniAppUrl = `${botUrl}/${miniAppName}`;
            const shareText = `🔥🔥🔥 ${miniAppUrl}\n\nI'm already immersed in some captivating short dramas on ShorTV, with opportunities to watch for free. Join me to experience more exciting stories! Tune in now!\n\n👆Click Link above to open 🌈⬆️`;
            this.copyText(shareText);
        },
        // 更多方法
        async moreBtnOnClick() {
            const botUrl = process.env.VUE_APP_BOT_URL;
            const miniAppName = process.env.VUE_APP_MINIAPP;
            const miniAppUrl = `${botUrl}/${miniAppName}`;
            const shareText = `I'm already immersed in some captivating short dramas on ShorTV, with opportunities to watch for free. Join me to experience more exciting stories! Tune in now!\n\n👇Click Link below to open 🌈⬇️\n\n`;
            try {
                window.navigator
                    .share({
                        text: shareText,
                        url: miniAppUrl,
                    })
                    .then(() => {
                        console.log("navigator share success");
                    })
                    .catch((err) => {
                        throw new Error(err);
                    });
            } catch (err) {
                console.log("navigator share error: ", err);
            }
        },
        // 分享到聊天室
        shareToChatBtnOnClick() {
            // ? 現在沒有配置 bot 的 inline mode 邀請訊息，先復用 shareLinkBtnOnClick
            this.shareLinkBtnOnClick();
        },
    },
    mounted() {
        if (!!navigator.share && navigator.userAgent.includes("Mobile")) {
            this.isNavigatorShareAvailable = true;
        }
    },
};
</script>
