<template>
    <transition name="fade">
        <div
            v-show="isLanguageSelectorActive"
            class="fixed left-0 top-0 z-10 h-screen w-screen"
        >
            <!-- overlay -->
            <div
                @click="close"
                class="absolute left-0 top-0 z-[-1] h-full w-full"
            ></div>
            <!-- panel content -->
            <div
                class="absolute bottom-0 left-0 flex h-[calc(100%-40px)] w-full flex-col gap-5 rounded-t-xl bg-[#000] bg-opacity-65 p-6"
                style="backdrop-filter: blur(10px)"
            >
                <!-- close button -->
                <button
                    @click="close"
                    class="absolute right-3 top-3 h-7 w-7 opacity-15"
                >
                    <img
                        src="assets/imgs/utils/icons/close_light.svg"
                        alt=""
                        class="h-full w-full"
                    />
                </button>
                <!-- title -->
                <span class="text-2xl font-bold italic text-[#fff]"
                    >LANGUAGE</span
                >
                <!-- languages wrapper container -->
                <div class="no-scrollbar w-full flex-grow overflow-scroll">
                    <!-- languages wrapper -->
                    <div class="flex w-full flex-wrap items-start gap-4">
                        <!-- language button -->
                        <button
                            v-for="(lang, index) in langs"
                            :key="index"
                            @click="() => langBtnOnClick(lang)"
                            class="flex h-12 w-[calc(50%-8px)] items-center justify-center gap-[10px] rounded-full bg-[#fff] bg-opacity-50"
                            :class="{
                                '!bg-opacity-100':
                                    currentSelectedLanguage === lang,
                            }"
                        >
                            <div
                                v-show="currentSelectedLanguage === lang"
                                class="flex h-5 w-5 items-center justify-center rounded-full bg-[#FC2956] text-center"
                            >
                                <svg-icon
                                    type="mdi"
                                    :path="mdiCheck"
                                    class="w-4 text-[#fff]"
                                ></svg-icon>
                            </div>
                            <span class="text-xl font-bold italic text-[#000]">
                                {{ $t(`language.${lang}`) }}
                            </span>
                        </button>
                    </div>
                </div>
                <!-- confirm button -->
                <button
                    @click="confirmBtnOnClick"
                    class="flex h-14 w-full items-center justify-center rounded-full bg-[#FC2956] text-center text-xl font-bold italic text-[#fff]"
                >
                    {{ $t("utils.confirm") }}
                </button>
            </div>
        </div>
    </transition>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiCheck } from "@mdi/js";

export default {
    name: "LanguageSelector",
    components: {
        SvgIcon,
    },
    props: {
        isLanguageSelectorActive: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        mdiCheck,
        currentSelectedLanguage: null,
        langs: ["en", "zh", "ru"],
    }),
    watch: {
        isLanguageSelectorActive(newVal) {
            // 開啟時獲取當前語言
            if (newVal) {
                this.currentSelectedLanguage = this.$i18n.locale;
            }
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        langBtnOnClick(lang) {
            this.currentSelectedLanguage = lang;
        },
        confirmBtnOnClick() {
            this.$i18n.locale = this.currentSelectedLanguage;
            localStorage.setItem("lang", this.currentSelectedLanguage);
            this.close();
        },
    },
};
</script>
