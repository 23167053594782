<template>
    <div class="h-full w-full overflow-hidden">
        <video
            :ref="refName"
            id="videoPlayer"
            class="video-js vjs-default-skin"
            controls
            playsinline
            webkit-playsinline
            preload="none"
            :muted="isMute"
            :autoplay="autoPlay"
            :loop="loop"
        >
            <source :src="videoSrc" type="application/x-mpegURL" />
        </video>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import Hls from "hls.js";
import "videojs-contrib-quality-levels";

export default {
    name: "VideoPlayer",
    props: {
        refName: {
            type: String,
            default: "",
            required: true,
        },
        coverScreen: {
            type: Boolean,
            default: false,
        },
        isMute: {
            type: Boolean,
            default: true,
        },
        loop: {
            type: Boolean,
            default: false,
        },
        autoPlay: {
            type: Boolean,
            default: false,
        },
        isPaused: {
            type: Boolean,
            default: null,
        },
        currentEpisodeStartTime: {
            type: Number,
            default: 0,
        },
        currentPlayingSeconds: {
            type: Number,
            default: null,
        },
        videoSrc: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        player: null,
        hls: null,
        levels: null,
    }),
    computed: {
        ...mapGetters(["videoQuality"]),
    },
    watch: {
        isPaused(newVal) {
            const isVideoPaused = this.player.paused();
            if (newVal) {
                if (isVideoPaused) return;
                this.player.pause();
            } else {
                if (!isVideoPaused) return;
                this.player.play();
            }
        },
        currentPlayingSeconds(newVal) {
            const newTimeNumber = Math.floor(Number(newVal));
            this.player.currentTime(newTimeNumber);
        },
        // ? 有新影片資源傳入被更新資源時自動播放
        videoSrc(newVal, prevVal) {
            if (newVal && prevVal) {
                this.player.src(newVal);
                this.player.play();
            }
        },
        // ? 有新的清晰度配置變更時更新清晰度
        videoQuality(newVal, prevVal) {
            if (newVal && prevVal) {
                console.log(
                    `video quality switched, from ${prevVal} to ${newVal}`,
                );
                this.switchQuality(newVal);
            }
        },
    },
    methods: {
        initializePlayer() {
            this.player = videojs(this.$refs[this.refName], {
                autoplay: this.autoPlay,
                muted: this.isMute,
                controls: false,
                fluid: this.coverScreen,
            });

            if (this.coverScreen) {
                const videoDOM = this.$refs[this.refName];
                console.log("videoDOM: ", videoDOM);
                videoDOM.classList.add("cover-screen");
            }

            if (Hls.isSupported()) {
                this.hls = new Hls({
                    maxBufferLength: 2,
                    autoStartLoad: false, // 禁止自動下載 TS 片段
                });

                this.hls.currentLevel = 4;
                this.hls.loadSource(this.videoSrc);
                this.hls.attachMedia(this.$refs[this.refName]);
                // this.hls.stopLoad();

                // 監聽 manifest parsed 事件
                this.hls.on(Hls.Events.MANIFEST_PARSED, (_, data) => {
                    console.log("HLS manifest data: ", data);
                    console.log("hls: ", this.hls);
                    this.levels = data.levels;
                    this.player.currentTime(this.currentEpisodeStartTime);
                    this.setupQualityLevels();
                    this.registerListeners();
                    this.$emit("onVideoLoaded");
                });

                // 監聽 解析度切換事件
                this.hls.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
                    console.log("Switched to level:", data.level);
                });

                // 監聽錯誤
                this.hls.on(Hls.Events.ERROR, (event, data) => {
                    console.error("HLS error", data);
                });
            } else if (
                this.$refs[this.refName].canPlayType(
                    "application/vnd.apple.mpegurl",
                )
            ) {
                this.$refs[this.refName].src = this.videoSrc;
                this.$refs[this.refName].addEventListener(
                    "loadedmetadata",
                    () => {
                        this.player.currentTime(this.currentEpisodeStartTime);
                        this.registerListeners();
                        this.$emit("onVideoLoaded");
                    },
                );
            }
        },
        setupQualityLevels() {
            const qualityLevels = this.player.qualityLevels();
            let levelsCount = 0;
            qualityLevels.on("addqualitylevel", (event) => {
                levelsCount++;
                const qualityLevel = event.qualityLevel;
                const localQuality = localStorage.getItem("quality");
                const targetQualityLevel = this.levels.find(
                    (level) => `${level.height}` === `${localQuality}`,
                );
                if (localQuality && targetQualityLevel) {
                    if (`${localQuality}` === `${qualityLevel.height}`) {
                        qualityLevel.enabled = true;
                    } else {
                        qualityLevel.enabled = false;
                    }
                } else {
                    qualityLevel.enabled = true;
                }
                if (levelsCount === this.levels.length) {
                    console.log("levels 已全部添加完畢，開始下載 ts");
                    console.log("qualityLevels: ", qualityLevels);
                    const targetQualityLevelIndex = this.levels.findIndex(
                        (level) => `${level.height}` === `${localQuality}`,
                    );
                    console.log(
                        "targetQualityLevelIndex: ",
                        targetQualityLevelIndex,
                    );
                    this.hls.currentLevel = targetQualityLevelIndex;
                    this.hls.startLoad();
                }
            });
            console.log("current qualityLevels: ", qualityLevels);
        },
        switchQuality(quality) {
            this.hls.stopLoad();
            const targetHeight = quality;
            const targetLevelIndex = this.levels.findIndex(
                (level) => `${level.height}` === `${targetHeight}`,
            );
            const qualityLevels = this.player.qualityLevels();
            this.player.currentLevel = targetLevelIndex;
            // 禁用目標畫質以外的所有 level，並 enable 所選畫質選項
            qualityLevels.levels_.forEach((level) => {
                level.enabled = `${level.height}` === `${targetHeight}`;
            });
            const currentPlayTime = this.player.currentTime();
            this.player.reset();
            this.player.src(this.videoSrc);
            this.player.currentTime(currentPlayTime);
            this.hls.startLoad();
        },
        listenPlay() {
            this.player.on("play", () => {
                this.$emit("onPlay");
            });
        },
        listenPause() {
            this.player.on("pause", () => {
                this.$emit("onPause");
                console.log("current HLS: ", this.hls);
            });
        },
        listenWaiting() {
            this.player.on("waiting", () => {
                this.$emit("onWaiting");
            });
        },
        listenCanPlay() {
            this.player.on("canplay", () => {
                this.$emit("onCanPlay");
            });
        },
        listenEnded() {
            this.player.on("ended", () => {
                this.$emit("onEnded");
            });
        },
        listenTimeUpdate() {
            this.player.on("timeupdate", () => {
                const currentTime = this.player.currentTime();
                const duration = this.player.duration();
                const progressStatus = {
                    currentTime,
                    duration,
                };
                this.$emit("updateProgress", progressStatus);
            });
        },
        registerListeners() {
            this.listenPlay();
            this.listenPause();
            this.listenCanPlay();
            this.listenWaiting();
            this.listenTimeUpdate();
            this.listenEnded();
        },
    },
    mounted() {
        this.initializePlayer();
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose();
        }
        if (this.hls) {
            this.hls.destroy();
        }
    },
};
</script>

<style lang="scss" scoped>
.video-js {
    width: 100%;
    height: 100% !important;
}

video {
    &.cover-screen {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
</style>
